import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppLoaderComponent } from '../app-loader.component';

@Injectable({
	providedIn: 'root'
})
export class LoaderService {
	loaderModal = null;
	constructor(private modal: MatDialog) {}

	showLoader() {
		if (this.loaderModal == null)
			this.loaderModal = this.modal.open(AppLoaderComponent, {
				height: '180px',
				width: '180px',
				panelClass: 'loading-dialog',
				disableClose: true,
				backdropClass: 'light-backdrop'
			});
	}

	hideLoader() {
		this.loaderModal.close();
		this.loaderModal = null;
	}
}
