import { BehaviorSubject, Observable, of } from 'rxjs';
import { EnumerationEx } from '../../_models/enumeration';
// import { UpdateProfile } from '../../user-management/models/update-profile';
import { AddUser } from '../../_models/user-models/register-details';
import { exhaust, map, take } from 'rxjs/operators';
import { SetPassword } from '../../_models/identity-models/set-password';
import { ChangePassword } from '../../_models/identity-models/change-password';
import { User } from '../../_models/user-models/user';
import { UpdateDetails } from '../../_models/user-models/update-details';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseApiUrl } from '../base-api-urls';
// import { NewUserModel } from '../../user-management/models/new-user-model';
import { UserStateEnum } from './user-state.enum';
import { IBigCommerceAuth } from 'src/app/authentication/_models/login.model';
import { StorageKey } from 'src/app/_models/local-storage-key';

@Injectable({
	providedIn: 'root'
})
export class UserServiceApi {
	public prevPage = new BehaviorSubject<string>(null);
	private readonly baseURL = `${BaseApiUrl.Users}`;
	private readonly authURL = '';

	constructor(protected http: HttpClient) {}

	public getCatalogs(): Observable<{ [key: string]: EnumerationEx[] }> {
		return this.http.get<{ [key: string]: EnumerationEx[] }>(`${this.baseURL}/catalogs`);
	}

	// public updateProfile(details: UpdateProfile): Observable<object> {
	// 	return this.http.put(`${this.baseURL}/update-profile`, details);
	// }

	public register(registerDetails: AddUser): Observable<object> {
		return this.http.post(`${this.baseURL}/`, registerDetails);
	}

	public resetPassword(detail: string): Observable<object> {
		return this.http.put(`${this.baseURL}send-password-link/`, { userEmail: detail });
	}

	public forgotPassword(setPassword: SetPassword): Observable<object> {
		return this.http.put(`${this.baseURL}forgot-password`, setPassword);
	}

	public changePassword(changePassword: ChangePassword): Observable<object> {
		return this.http.put(`${this.baseURL}/change-password`, changePassword).pipe(
			map(() => {
				return of({});
			})
		);
	}

	public authenticate(username: string, password: string, code?: string): Observable<string> {
		return this.http.post<string>(`${this.baseURL}signIn`, { EmailAddress: username, Password: password, code: code });
	}

	public login(username: string, password: string): Observable<string> {
		console.log(this.authURL, 'auth url');
		return this.http.post<string>(this.authURL + 'login', { Email: username, Password: password });
	}

	public refreshUserToken(): Observable<string> {
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		if (token.platform_name == 'shopify') {
			return this.http.get<string>(`${BaseApiUrl.Subscription}Account/refresh-token?platform=shopify`);
		}
		return this.http.get<string>(`${BaseApiUrl.Subscription}Account/refresh-token`);
	}

	public googleSignin(idToken: string): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Subscription}ExternalSignIn/google-authenticate`, { idToken });
	}

	public getShopifyUserStatus(): Observable<boolean> {
		return this.http.get<boolean>(`${BaseApiUrl.Subscription}shopify/SAccount/check/user`);
	}

	public googleSignup(details: object): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Subscription}ExternalSignUp/google-signup`, details);
	}
	public googleV2Signup(details: object): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SubscriptionSecond}ExternalSignUp/google-signup`, details);
	}

	public verifyShakespeareEmail(emailAddress: string, token: string): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Account/verify-email`, { emailAddress, token });
	}

	public getSessionId(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}Subscription/sessionid`);
	}

	public getUserById(userId: number, isAdmin: boolean): Observable<User> {
		if (isAdmin) {
			return this.http.get<User>(`${BaseApiUrl.Users}BackOfficeUsers/employees/${userId}`);
		} else {
			return this.http.get<User>(`${BaseApiUrl.Users}detail`);
		}
	}

	public getAllUsers(): Observable<User[]> {
		return this.http.get<User[]>(`${this.baseURL}`);
	}

	public getUserActivity(): Observable<any[]> {
		return this.http.get<any[]>(`${this.baseURL}/account-activities`);
	}

	public getBusinessOwnerEmail(): Observable<string> {
		return this.http.get<string>(`${this.baseURL}/get-businessowner-email`);
	}

	public getAllUsersByOwnerFacebookId(ownerFacebookId: string): Observable<User[]> {
		return this.http.get<User[]>(`${this.baseURL}/owned/${ownerFacebookId}`);
	}

	// public createClientEmployee(user: NewUserModel): Observable<number> {
	// 	return this.http.post<number>(`${this.baseURL}/clientEmployees`, user);
	// }

	// public updateClientEmployee(userDetails: NewUserModel, userId: number): Observable<object> {
	// 	return this.http.put(`${this.baseURL}/clientEmployees/${userId}`, userDetails);
	// }

	// public createAndActivateClientEmployee(user: NewUserModel): Observable<number | object> {
	// 	return this.createClientEmployee(user).pipe(
	// 		take(1),
	// 		map(userId => this.activateUser(userId)),
	// 		exhaust()
	// 	);
	// }

	public updateUser(userId: number, userDetails: UpdateDetails): Observable<object> {
		return this.http.put(`${this.baseURL}/${userId}`, userDetails);
	}

	public updateUserPicture(file: FormData): Observable<object> {
		return this.http.put(`${this.baseURL}/update-picture`, file);
	}

	public deactivateUser(userId: number): Observable<object> {
		return this.http.put(`${this.baseURL}/${userId}/disable-user`, { userId: userId });
	}

	public deactivateUserNew(user: any): Observable<object> {
		return this.http.put(`${this.baseURL}/disable-user`, user);
	}

	public activateUser(userId: number): Observable<object> {
		return this.http.put(`${this.baseURL}/${userId}/enable-user`, { userId: userId });
	}

	public backOfficeDeactivateUser(userId: number): Observable<object> {
		return this.http.put(`${BaseApiUrl.Users}BackOfficeUsers/${userId}/disable-user`, {});
	}

	public backOfficeActivateUser(userId: number): Observable<object> {
		return this.http.put(`${BaseApiUrl.Users}BackOfficeUsers/${userId}/enable-user`, { userId: userId });
	}

	public removeUser(userId: number): Observable<object> {
		return this.http.delete(`${this.baseURL}/${userId}`);
	}

	public backOfficeRemoveUser(userId: number): Observable<object> {
		return this.http.delete(`${BaseApiUrl.Users}BackOfficeUsers/${userId}`);
	}

	public getUserAccountStatus(filedId: number): Observable<UserStateEnum> {
		return this.http.get<UserStateEnum>(`${this.baseURL}/${filedId}/user-account-status`);
	}

	public confirmUserEmail(email: string): Observable<boolean> {
		return this.http.get<boolean>(`${BaseApiUrl.Users}Users/verifyEmail?emailId=${email}`);
	}

	public bigCommerceAuth(payload: IBigCommerceAuth): Observable<HttpResponse<any>> {
		return this.http.post<any>(`${this.baseURL}/bigCommerce/signin`, payload, { observe: 'response' });
	}

	public generateAdCopyData(request): Observable<HttpResponse<any>> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/text/generate`, request, { observe: 'response' });
	}

	public addLikedFeedback(request): Observable<HttpResponse<any>> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/text/feedback`, request, { observe: 'response' });
	}

	public getCreditScore(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/credit`, request, { observe: 'response' });
	}

	public getCreditReport(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/credit/report`, request, { observe: 'response' });
	}

	public getUserTemplates(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/most-used-templates`, request, { observe: 'response' });
	}

	public generateEmailData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/email`, request, { observe: 'response' });
	}

	public generateProductDescriptionData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/product-description`, request, { observe: 'response' });
	}

	public shopifyGenerator(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/ecommerce/shopify/product-description-generate`, request, {
			observe: 'response'
		});
	}

	public BcGenerator(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/ecommerce/big-commerce/product-description-generate`, request, {
			observe: 'response'
		});
	}

	public generateBlogData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/blog/generate`, request, { observe: 'response' });
	}

	public generateEssayData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/essay/generate`, request, { observe: 'response' });
	}

	public generateContentData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/content-improver`, request, { observe: 'response' });
	}

	public generateSocialData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/social/caption`, request, { observe: 'response' });
	}

	public generateHashTagData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/social/hashtags`, request, { observe: 'response' });
	}

	public generateInstagramData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/social/instagram`, request, { observe: 'response' });
	}

	public generateVideoData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/social/video`, request, { observe: 'response' });
	}

	public generateFrameworkData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/framework`, request, { observe: 'response' });
	}
	public generateSeoData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/seo`, request, { observe: 'response' });
	}
	public generateBioData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/bio`, request, { observe: 'response' });
	}

	public createUserProject(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/projects/create`, request, { observe: 'response' });
	}

	public addToUserProject(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/projects/add`, request, { observe: 'response' });
	}

	public getUserProjects(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/projects/retrive`, request, { observe: 'response' });
	}

	public deleteOutputFromProject(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/projects/remove`, request, { observe: 'response' });
	}

	public deleteProject(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/projects/delete`, request, { observe: 'response' });
	}

	public deleteFolder(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/folder/delete`, request, { observe: 'response' });
	}

	public getUserFolders(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/folder/retrive`, request, { observe: 'response' });
	}

	public createFolder(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/folder/create`, request, { observe: 'response' });
	}

	public addToFolder(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/folder/add`, request, { observe: 'response' });
	}

	public deleteFromFolder(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/folder/remove`, request, { observe: 'response' });
	}

	public getHistoryData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/history`, request, { observe: 'response' });
	}

	public deleteHistoryItem(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/history/delete`, request, { observe: 'response' });
	}

	public restoreFromTrash(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/trash/restore`, request, { observe: 'response' });
	}

	public getTrashHistory(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/trash`, request, { observe: 'response' });
	}

	public getAutoCompletion(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/advanced/completion`, request, { observe: 'response' });
	}
	public getContentImprover(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/content-improver`, request, { observe: 'response' });
	}

	public getProUserStatus(request: object): Observable<object> {
		return this.http.get<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/user/pro`, request);
	}

	public addTemplateHistory(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/template-history/create`, request, { observe: 'response' });
	}

	public getTemplateHistory(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/template-history/retrieve`, request, { observe: 'response' });
	}

	public addFavTemplates(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/favorite/add`, request);
	}

	public removeFavTemplates(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/favorite/remove`, request);
	}

	public getFavTemplates(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/favorite/retrieve`, request);
	}

	public renameProjectOrFolder(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/folder/rename`, request);
	}

	public getFolderData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/folder/retrive`, request);
	}

	public addDataToFolder(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/folder/add`, request);
	}

	public deleteTemplateHistoryItem(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/template-history/delete`, request);
	}

	public addFavHistory(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/heart/add`, request);
	}

	public removeFavHistory(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/heart/remove`, request);
	}

	public retrieveFavHistory(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/heart/retrieve`, request);
	}

	public getIsFirstTimeCustomer(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}Account/welcomeflow`);
	}
	public finishIsFirstTimeCustomer(): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Account/finish/welcomeflow`, {});
	}
}
