import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { StorageKey } from '../_models/local-storage-key';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { TokenService } from './token.service';
import { UserDetails } from '../_models/identity-models/identity-detail';
import { UserStateEnum } from './user/user-state.enum';
import { select, Store } from '@ngrx/store';
import { LogOut, RestoreAuthentication } from '../authentication/state/authentication.action';
import { getAccountState, hasBackOfficePermission, hasClientPermission, UserState } from '../shared/state/user/user.reducer';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthenticationState } from '../authentication/state/authentication.reducer';
import { ResetAppState } from '../state/app.actions';
import moment from 'moment';
import { PermissionsService } from '../shared/permisions/permissions.service';
import { UserDetailsInterface } from '../_models/identity-models/user-details.interface';
import { SetUserDetails } from '../shared/state/user/user.actions';
import { ShakespeareService } from '../shakespeare/shakespeare.service';
import { HttpClient } from '@angular/common/http';
import { EditorService } from '../shakespeare/ads/facebook/editor/editor.service';
import { BackOfficeService } from './back-office/back-office.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	public logoutSubject: Subject<void> = new Subject();
	public setRoute$: BehaviorSubject<string> = new BehaviorSubject('');
	public allowedMegaRoutes$: BehaviorSubject<any[]> = new BehaviorSubject(null);
	public bigCommerceAction$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public setSubNavIndex$: BehaviorSubject<number> = new BehaviorSubject(null);
	public expandMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public navMenuRoute$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public setMegaRouteIndex$: BehaviorSubject<number> = new BehaviorSubject(0);
	public setParentRoute$: BehaviorSubject<string> = new BehaviorSubject('');
	public signupVersion$: BehaviorSubject<string> = new BehaviorSubject('quickSignup');
	public loginParam$: BehaviorSubject<string> = new BehaviorSubject('');
	public googleAdAccountConnected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public redirectToExtension$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public extensionTabId$: BehaviorSubject<string> = new BehaviorSubject('');
	public extensionId$: BehaviorSubject<string> = new BehaviorSubject('');
	public brand: BehaviorSubject<any> = new BehaviorSubject({});
	public brand$ = this.brand.asObservable();
	private userDetails: UserDetails;
	public checkingFirstTimeUser: any = null;
	public defaultSelectedPlatform: any = null;

	constructor(
		private router: Router,
		private tokenService: TokenService,
		private permissionsService: PermissionsService,
		private userStore: Store<UserState>,
		private authStore: Store<AuthenticationState>,
		private shakespeareService: ShakespeareService,
		private http: HttpClient,
		private editorService: EditorService,
		private backOfficeService: BackOfficeService
	) {}

	public getBrand(cb?): Observable<any> {
		return new Observable(observer => {
			this.editorService.getBranding().subscribe(
				res => {
					this.brand.next(res);
					this.checkingFirstTimeUser = res;
					if (res.data?.companyName) {
						this.getDefaultSelectedPlatform(cb);
					}
					observer.next(this.checkingFirstTimeUser);
					observer.complete();
				},
				error => {
					observer.error(error);
					observer.complete();
				}
			);
		});
	}

	public getBrand1() {
		return this.editorService.getBranding();
	}

	public getDefaultSelectedPlatform(cb?) {
		this.backOfficeService
			.getDefaultSelectedPlatform()
			.subscribe(res => {
				this.defaultSelectedPlatform = res;
			})
			.add(() => {
				if (cb) cb();
			});
	}

	public getDefaultSelectedPlatform1() {
		return this.backOfficeService.getDefaultSelectedPlatform();
	}

	public isLoggedIn() {
		const tokenExists: boolean = !!localStorage.getItem(StorageKey.token);
		return tokenExists;
		// if (tokenExists) {
		// 	const decodedJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		// 	const currentTime = moment().format();
		// 	const timeFromJwt = moment(decodedJwt.exp * 1000).format();
		// 	const decodedJwtIsValid: boolean = !moment(currentTime).isAfter(timeFromJwt);
		// 	this.initUserDetails();

		// 	return tokenExists && decodedJwtIsValid;
		// }
	}
	public initUserDetails(): void {
		if (!this.userDetails) {
			const decodedToken = this.tokenService.decodeToken(localStorage.getItem(StorageKey.token));
			this.userDetails = this.getUserDetailsFromJwt(decodedToken);
		}
	}

	public hasCreditCard(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState !== UserStateEnum.NoCreditCard && accountState !== UserStateEnum.FreeTrialExpiredNoCreditCard))
		);
	}

	public hasBusinessOwner(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => {
				return of(accountState !== UserStateEnum.NoBusinessOwner);
			})
		);
	}

	public hasClientEmployee(): Observable<boolean> {
		return this.userStore.pipe(
			select(hasClientPermission),
			take(1),
			switchMap(isClientEmployee => of(isClientEmployee))
		);
	}

	public isAdmin(): Observable<boolean> {
		return this.userStore.pipe(select(hasBackOfficePermission), take(1));
	}

	public isFreemiumExpired(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState === UserStateEnum.FreemiumExpiredNoCreditCard))
		);
	}

	public isFreeTrialExpired(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState === UserStateEnum.FreeTrialExpiredNoCreditCard))
		);
	}

	public is7DayFreeTrialUser(): boolean {
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		return token ? token?.user_account_state == UserStateEnum.BillPendingUnrestrictedAccess : false;
	}

	public logout(params?: NavigationExtras): void {
		// TODO hack because we dont have refresh token
		const temporaryCredentials = localStorage.getItem('details');
		localStorage.clear();
		if (temporaryCredentials) {
			localStorage.setItem('details', temporaryCredentials);
		}

		this.shakespeareService.shopifyUser$.next(false);
		this.shakespeareService.bigCommerceUser$.next(false);

		this.logoutSubject.next();

		this.userStore.dispatch(new ResetAppState());
		this.authStore.dispatch(new LogOut());

		params ? this.router.navigate(['/authentication'], params) : this.router.navigate(['/authentication']);
	}

	public async initUserRolesLogic(): Promise<void> {
		this.tokenService.token = await localStorage.getItem(StorageKey.token);
		if (!this.tokenService.token) {
			return;
		}

		try {
			const decodedToken = this.tokenService.decodeToken(localStorage.getItem(StorageKey.token));
			this.userDetails = this.getUserDetailsFromJwt(decodedToken);
			this.userStore.dispatch(new SetUserDetails(this.userDetails));
			this.authStore.dispatch(new RestoreAuthentication(this.tokenService.token));
		} catch (e) {
			this.logout();
		}
	}

	public getUserDetailsFromJwt(decodedJwt: UserDetailsInterface): UserDetails {
		const codedPermissions: string[] = decodedJwt.permissions_filed.split('|') || [];
		const decodedPermissions = codedPermissions
			.map(permission => this.permissionsService.decodePermission(permission))
			.filter(permission => permission.permissions.length);
		return {
			FiledId: parseInt(decodedJwt.user_filed_id),
			OriginalFiledId: parseInt(decodedJwt.original_filed_id),
			AccountState: parseInt(decodedJwt.user_account_state),
			IsFrontOfficeUser: decodedJwt.user_is_frontoffice_user === 'True',
			RejectedReason: parseInt(decodedJwt.user_rejected_reason),
			IsImpersonated: false,
			Permissions: decodedPermissions,
			FiledParentId: decodedJwt.filed_parent_id,
			PlatformName: decodedJwt.platform_name,
			shopifyDetailAvailable: decodedJwt.shopify_detail_available,
			BigCommerceDetailAvailable: decodedJwt.bigcommerce_detail_available
		};
	}

	public getUserDetailsFromJwt_v2(decodedJwt: UserDetailsInterface) {
		return {
			FiledId: parseInt(decodedJwt.user_filed_id),
			OriginalFiledId: parseInt(decodedJwt.original_filed_id),
			AccountState: parseInt(decodedJwt.user_account_state),
			IsFrontOfficeUser: decodedJwt.user_is_frontoffice_user === 'True',
			RejectedReason: parseInt(decodedJwt.user_rejected_reason),
			IsImpersonated: false,
			// Permissions: decodedPermissions,
			FiledParentId: decodedJwt.filed_parent_id,
			PlatformName: decodedJwt.platform_name,
			shopifyDetailAvailable: decodedJwt.shopify_detail_available,
			BigCommerceDetailAvailable: decodedJwt.bigcommerce_detail_available
		};
	}
}
