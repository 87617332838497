import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { SharedDropdownService } from './service/shared-dropdown.service';
import { of, tap, map, distinctUntilChanged, pipe } from 'rxjs';
import { FacebookDataService } from '../shakespeare/ads/facebook/facebook-data.service';
import { AdsService } from '../shakespeare/ads/ads.service';
import { LayoutService } from '../layout/services/layout.service';
import { GoogleDataService } from '../shakespeare/ads/google/services/google-data.service';
import { SubSink } from 'subsink';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-shared-dropdown',
	templateUrl: './shared-dropdown.component.html',
	styleUrls: ['./shared-dropdown.component.scss']
})
export class SharedDropdownComponent implements OnInit, OnChanges, OnDestroy {
	@Input() dropdownToShow;
	@Input() parentOutputKey;
	@Output() dropdownValues = new EventEmitter();
	@Input() page: string;
	platformConfig: any = {
		label: 'platform',
		multi: false,
		showSearch: false,
		formController: new FormControl(),
		isLoading: true,
		data: [],
		class: 'singleSelect',
		emptyMessage: 'No Platform',
		key: 'platform'
	};
	adAccountConfig: any = {
		label: 'Ad Account',
		multi: false,
		data: [],
		showSearch: true,
		formController: new FormControl(),
		class: 'singleSelect',
		emptyMessage: 'No Account',
		key: 'adAccount'
	};
	AiCategoryConfig: any = {
		label: 'AI Category',
		multi: false,
		data: [],
		formController: new FormControl(),
		showSearch: true,
		class: 'singleSelect',
		emptyMessage: 'No AI Category',
		key: 'aiCategory'
	};
	abc = new FormControl();
	subs = new SubSink();
	adSetConfig: any = {
		label: 'Ad Sets',
		multi: true,
		data: [],
		showSearch: false,
		class: 'multiSelect',
		formController: new FormControl(),
		emptyMessage: 'No Ad Sets',
		key: 'adSet'
	};
	campaignConfig: any = {
		label: 'Campaigns',
		multi: true,
		data: [],
		showSearch: false,
		formController: new FormControl(),
		class: 'singleSelect',
		emptyMessage: 'No Campaigns',
		key: 'adCampaign'
	};
	defaultPlatform = 'facebook';
	platformLoader = true;
	adAccountLoader = true;
	aiCategoryLoader = true;
	adSetLoader = true;
	campaignLoader = true;
	campaignData = [];

	constructor(
		public sharedDropdown: SharedDropdownService,
		private adService: AdsService,
		private facebookService: FacebookDataService,
		private googleService: GoogleDataService,
		private activeRotue: ActivatedRoute,
		private layoutService: LayoutService
	) {}

	ngOnChanges(changes: SimpleChanges): void {}
	ngOnInit(): void {
		console.log('ng on it loading..', this.parentOutputKey);
		this.setPlatformOptions();
	}

	setPlatformOptions() {
		this.platformLoader = true;
		let platform = [];

		console.log(platform, 'platform');
		let google = this.sharedDropdown.platforms[0];
		let facebook = this.sharedDropdown.platforms[1];

		if (this.layoutService.isGoogleConnected) {
			platform.push(google);
		}
		if (this.layoutService.isFacebookConnected) {
			platform.push(facebook);
		}
		if (this.page == 'facebook') {
			this.platformConfig.formController.setValue(facebook);
			this.setAdAccountOptions(this.facebookService.fbAccounts);
		} else if (this.page == 'google') {
			this.platformConfig.formController.setValue(google);
			this.setAdAccountOptions(this.googleService.googleAdAccount);
		} else {
			if (platform.length) {
				let p = platform[0];
				this.platformConfig.formController.setValue(p);
				if (p.id == 'google') {
					this.setAdAccountOptions(this.googleService.googleAdAccount);
				} else {
					this.setAdAccountOptions(this.facebookService.fbAccounts);
				}
			}
		}

		this.platformLoader = false;
		//only working for facebook
	}

	generateDropdownJson() {
		return {
			platform: this.platformConfig.formController.value,
			adAccount: this.adAccountConfig.formController.value,
			aiCategory: this.AiCategoryConfig.formController.value,
			adCampaign: this.campaignConfig.formController.value,
			adSet: this.adSetConfig.formController.value
		};
	}

	sendDropdownDataToParent(config) {
		// let key = this.dropdownToShow[this.dropdownToShow.length - 1];
		console.log(config.key, this.parentOutputKey, '==>', config.key == this.parentOutputKey);
		if (config.key == this.parentOutputKey) {
			this.dropdownValues.emit(this.generateDropdownJson());
		}
	}

	setAdAccountOptions(res) {
		this.adAccountLoader = false;
		this.adAccountConfig.data = res;
		let setValue = res[0];
		this.adAccountConfig.formController.setValue(setValue);
		console.log(this.platformConfig.formController.value.id, 'platform');
		this.adAccountSelectionChange();
	}

	adAccountSelectionChange() {
		this.sendDropdownDataToParent(this.adAccountConfig);
		if (this.adAccountConfig.key == this.parentOutputKey) return;
		if (this.platformConfig.formController.value.id == 'facebook') {
			this.getFbAiCategory();
			this.getFacebookCampaign();
		} else {
			this.getGoogleAiCategory();
			this.getGoogleCampaign();
		}
	}

	setAiCategoryOptions(res) {
		this.aiCategoryLoader = false;
		this.AiCategoryConfig.data = res;
		let setValue = res[res.length - 1];
		this.AiCategoryConfig.formController.setValue(setValue);
		this.sendDropdownDataToParent(this.AiCategoryConfig);
	}

	setAdSetOption(res) {
		this.adSetLoader = false;
		this.adSetConfig.data = res;

		let setValue = res[0];
		this.adSetConfig.formController.setValue([setValue]);
		this.sendDropdownDataToParent(this.adSetConfig);
	}

	setCampaignOption(res) {
		this.campaignLoader = false;
		this.campaignConfig.data = res;
		let setValue = res;
		this.campaignConfig.formController.setValue(setValue);
		this.sendDropdownDataToParent(this.campaignConfig);
	}

	getFbAdAccount() {
		return this.facebookService.fbAccounts;
	}

	getGoogleAdAccount() {
		return this.googleService.googleAdAccount;
	}

	getFbRelatedData() {}

	//========================= FB Related Data ===================================================
	getFbAiCategory() {
		if (this.dropdownToShow.includes('aiCategory')) {
			this.aiCategoryLoader = true;
			this.subs.sink = this.adService
				.getSelectedAdaccountFbCategoriesIDs(this.adAccountConfig.formController.value.id)
				.pipe(
					map(res => {
						let data = res['data'];
						data.map(v => {
							v.id = v.aiCategoryId;
							return v;
						});
						return data;
					})
				)
				.subscribe(res => {
					this.setAiCategoryOptions(res);
				});
		}
	}
	getFacebookCampaign() {
		this.campaignLoader = true;
		this.subs.sink = this.facebookService
			.getFbCampaign(this.adAccountConfig.formController.value.id)
			.pipe(
				map(resp => {
					let d = resp['campaigns'];
					return d;
					return [{ id: 'ALL', name: 'ALL' }].concat(d);
				})
			)
			.subscribe(response => {
				console.log(response, 'response');
				this.campaignData = response;
				this.setCampaignOption(response);
				if (this.campaignConfig.key == this.parentOutputKey) return;
				this.getFbAdSet();
			});
	}
	getFbAdSet() {
		this.adSetLoader = true;
		let campaign = this.campaignConfig.formController.value;
		if (!campaign.length) {
			this.setAdSetOption([]);
			return;
		}
		this.subs.sink = this.facebookService.getFbAdSet(this.campaignConfig.formController.value[0].id).subscribe(
			res => {
				console.log(res);
				this.setAdSetOption(res['adsets']);
			},
			err => {
				console.log(err, 'error');
			}
		);
	}
	// <--------------------------------- Google Related Data ---------------------------------->

	getGoogleAiCategory() {
		this.aiCategoryLoader = true;
		this.subs.sink = this.adService
			.getCategoriesIDsOnDashboard(this.adAccountConfig.formController.value.id)
			.pipe(
				map(res => {
					let data = res['data'];
					data.map(v => {
						v.id = v.aiCategoryId;
						return v;
					});
					return data;
				})
			)
			.subscribe(res => {
				this.setAiCategoryOptions(res);
			});
	}
	getGoogleCampaign() {
		this.subs.sink = this.googleService
			.getGoogleCampaign(this.adAccountConfig.formController.value.id)
			.pipe(
				map(g => {
					let p = g['campaigns'];
					return p;
				})
			)
			.subscribe(response => {
				console.log('google', response);
				console.log(response, 'response');
				this.campaignData = response;
				this.setCampaignOption(response);
				if (this.campaignConfig.key == this.parentOutputKey) return;
				this.getGoogleAdSet();
			});
	}
	getGoogleAdSet() {
		this.subs.sink = this.googleService
			.getGoogleAdSet(this.adAccountConfig.formController.value.id, this.campaignConfig.formController.value[0].id)
			.pipe(
				map(g => {
					let d = g['adgroups'];
					d = d.map(c => {
						return { id: c.Id, name: c.Name, status: c.Status };
					});
					return d;
				})
			)
			.subscribe(res => {
				console.log(res);
				let data = res;
				this.setAdSetOption(data);
			});
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}
	handleDropdownSelectionChange(e) {
		console.log(e);
		// For Facebook

		if (e.config.key == 'adAccount') {
			this.adAccountSelectionChange();
		} else if (e.config.key == 'aiCategory') {
			this.dropdownValues.emit(this.generateDropdownJson());
		} else if (e.config.key == 'aiCampaign') {
			if (e.config.key == this.parentOutputKey) {
				this.dropdownValues.emit(this.generateDropdownJson());
				return;
			}
			this.getFbAdSet();
		} else if (e.config.key == 'adSet') {
			this.dropdownValues.emit(this.generateDropdownJson());
		}
	}
}
