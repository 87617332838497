import { Component, OnInit } from '@angular/core';
import { FacebookDataService } from '../shakespeare/ads/facebook/facebook-data.service';
import { GoogleDataService } from '../shakespeare/ads/google/services/google-data.service';
import { LoaderService } from '../app-loader/service/loader.service';
import { LayoutService } from './services/layout.service';
import { SharedDropdownService } from '../shared-dropdown/service/shared-dropdown.service';
import { catchError, forkJoin, of } from 'rxjs';
import { ToastNotificationService } from '../shared/toast-notification/toast-notification.service';
import { HelperService } from '../shakespeare/ads/ai-category-flow-fb-google/services/helper.service';
import { PaymentPlanService } from '../auth-v2/services/payment-plan.service';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
	loading = false;
	isLoading = true;
	html;
	constructor(
		private fbService: FacebookDataService,
		private googleService: GoogleDataService,
		private loaderService: LoaderService,
		private layoutService: LayoutService,
		private sharedDropdownService: SharedDropdownService,
		private toasterService: ToastNotificationService,
		private helperService: HelperService,
		private paymentPlanService: PaymentPlanService
	) {}

	ngOnInit(): void {
		// if (this.layoutService.platform == 'facebook') {
		// 	// this.sharedDropdownService.platform.next(this.)
		// 	this.validateFbToken();
		// } else if (this.layoutService.platform == 'google') {
		// 	this.validateGoogleToken();
		// } else {

		// }
		this.layoutService.initializeLayoutMethods$.subscribe(res => {
			this.isLoading = true;
			this.loaderService.showLoader();
			this.getPlanDetail();
			this.handleBothValidation();
		});
	}

	getPlanDetail() {
		this.paymentPlanService.getCurrentSubscription().subscribe(res => {
			console.log(res);
			this.layoutService.isProUser = res['is_pro'];
		});
	}

	handleBothValidation() {
		let fbValidate = this.fbService.validateFacebookAccessToken().pipe(
			catchError(err => {
				this.layoutService.isFacebookConnected = false;

				return of(null);
			})
		);
		let googleValidate = this.googleService.validateGoogleAccessToken().pipe(
			catchError(err => {
				this.layoutService.isGoogleConnected = false;
				return of(null);
			})
		);
		let fbData = this.fbService.getFbAccount().pipe(
			catchError(err => {
				console.log(err.error.message, 'error');

				return of(null);
			})
		);
		let googleData = this.googleService.getGoogleAdAccount().pipe(
			catchError(err => {
				return of(null);
			})
		);
		forkJoin([fbValidate, googleValidate]).subscribe(res => {
			console.log(res);
			if (res[0] != null && res[1] !== null) {
				//Both fb and google accessaable
				this.layoutService.isGoogleConnected = true;
				this.layoutService.isFacebookConnected = true;

				forkJoin([fbData, googleData]).subscribe(accountResp => {
					if (accountResp[0] !== null && accountResp[1] !== null) {
						// if both has data
						let fbAccounts = accountResp[0]['ad_accounts'];
						let googleAccounts = accountResp[1]['customers'];
						this.setRespectiveAccountData(fbAccounts, googleAccounts);
						this.resetLoadingState();
					} else if (accountResp[0] !== null) {
						// if fb has data
						let fbAccounts = accountResp[0]['ad_accounts'];
						this.setRespectiveAccountData(fbAccounts, null);
					} else {
						// if google has data
						let googleAccounts = accountResp[1]['customers'];
						this.setRespectiveAccountData(null, googleAccounts);
					}
				});
			} else if (res[0] != null) {
				// only fb accessable

				this.layoutService.isFacebookConnected = true;
				fbData.subscribe(
					accountResp => {
						let fbAccounts = accountResp['ad_accounts'];

						this.setRespectiveAccountData(fbAccounts, null);

						this.resetLoadingState();
					},
					err => {
						console.log(err);
					}
				);
			} else if (res[1] != null) {
				// only google accessable
				this.layoutService.isGoogleConnected = true;
				googleData.pipe(catchError(err => err)).subscribe({
					next: accountResp => {
						if (accountResp) {
							let googleAccount = accountResp['customers'];

							this.setRespectiveAccountData(null, googleAccount);
							this.resetLoadingState();
						} else {
							this.setRespectiveAccountData(null, null);
							this.resetLoadingState();
						}
					},
					error: err => {}
				});
			} else {
				alert('Platforms are not connected');
				this.helperService.navigate('layout/settings/integrations');
				this.loaderService.hideLoader();
				this.isLoading = false;
				this.toasterService.sendErrorToast('Platforms are not connected. Please connect aleast one platform', 5000);
			}
		});
	}

	setRespectiveAccountData(fbData, googleData) {
		this.fbService.fbAccounts = fbData;
		this.googleService.googleAdAccount = googleData;
	}
	resetLoadingState() {
		this.loaderService.hideLoader();
		this.isLoading = false;
	}

	validateFbToken() {
		this.fbService.validateFacebookAccessToken().subscribe(res => {
			this.fbService.getFbAccount().subscribe(
				res => {
					console.log(res);
					this.fbService.fbAccounts = res['ad_accounts'];
					this.layoutService.getPlatform().subscribe(res => {
						this.sharedDropdownService.platform.next(res);
						this.sharedDropdownService.AdAccount.next(this.fbService.fbAccounts);
						this.loaderService.hideLoader();
						this.isLoading = false;
					});
				},
				err => {
					console.log('facebook token is not valid');
				}
			);
		});
	}

	validateGoogleToken() {
		this.googleService.validateGoogleAccessToken().subscribe(res => {
			this.googleService.getGoogleAdAccount().subscribe(
				res => {
					console.log(res);
					this.sharedDropdownService.platform.next(res);
					this.sharedDropdownService.AdAccount.next(this.googleService.googleAdAccount);
					this.googleService.googleAdAccount = res['ad_accounts'];
				},
				err => {
					console.log('google token is not valid');
				}
			);
		});
	}
}
