import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { StorageKey } from '../_models/local-storage-key';

@Injectable({
	providedIn: 'root'
})
export class ShakespeareService {
	public triggerParams$: BehaviorSubject<string> = new BehaviorSubject('all-templates');
	public clearSettingSelection$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public isUserPro$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public isPaidUser$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public creditModalClosedOnce$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public userCredits$: BehaviorSubject<number> = new BehaviorSubject(null);
	public blogWizardSub$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public settingsRoute$: BehaviorSubject<string> = new BehaviorSubject('');
	public currentPriceId$: BehaviorSubject<string> = new BehaviorSubject('');
	public currentProductId$: BehaviorSubject<string> = new BehaviorSubject('');
	public currentPlanInterval$: BehaviorSubject<string> = new BehaviorSubject('');
	public mobileNavMargin$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public isTrialPlan$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public currentPrice$: BehaviorSubject<string> = new BehaviorSubject('');
	public currentPlan$: BehaviorSubject<string> = new BehaviorSubject(''); // 'pro' || 'standard'
	public isSettingsOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public settingsScrollHelper$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public toggleSettingsNav$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public isEndingFreeTrial$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public isRenewPlan$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public settingsRouteName$: BehaviorSubject<string> = new BehaviorSubject('');

	public autoScrolled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public uncheckAllTabs$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public isBonusOffer$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public hideOffer$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public templateActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public shopifyUser$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public shopifyActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public bigCommerceUser$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public isEnterpriceUser$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public accountPaused$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public accountCancelled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public fromWizardData$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public isFullScreenMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public fullScreenBackRoute$: BehaviorSubject<string> = new BehaviorSubject('');

	public variantsdata$: BehaviorSubject<[]> = new BehaviorSubject([]);
	public upgradePlanShift$: BehaviorSubject<any> = new BehaviorSubject('');
	sideMenuSelection = new BehaviorSubject(null);
	sideMenuSelection$ = this.sideMenuSelection.asObservable();

	constructor() {
		try {
			let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
			if (token?.shopify_detail_available == 'true') {
				this.shopifyUser$.next(true);
			}
			if (token?.bigcommerce_detail_available == 'true') {
				this.bigCommerceUser$.next(true);
			}
		} catch (error) {
			console.error(error);
		}
	}

	public isProdEnvironment(): boolean {
		return !window.location.href.includes('dev.shakespeare.ai');
	}

	public autoScroll(): void {
		this.autoScrolled$.next(false);
		setTimeout(() => {
			this.autoScrolled$.next(true);
		}, 300);
	}

	public autoScrollOff(): void {
		this.autoScrolled$.next(false);
	}

	fbAiCategoryLocalStorageLabel = 'createAiCategoryFlowFb';
	googleAiCategoryLocalStorageLabel = 'createAiCategoryFlowGoogle';
	removeShowAiCategoryFlowfb() {
		localStorage.removeItem(this.fbAiCategoryLocalStorageLabel);
	}

	setShowAiCategoryFlowfb() {
		localStorage.setItem(this.fbAiCategoryLocalStorageLabel, 'yes');
	}
	getShowAiCategoryFlowfb() {
		return localStorage.getItem(this.fbAiCategoryLocalStorageLabel);
	}
	removeShowAiCategoryFlowGoogle() {
		localStorage.removeItem(this.googleAiCategoryLocalStorageLabel);
	}

	setShowAiCategoryFlowGoogle() {
		localStorage.setItem(this.googleAiCategoryLocalStorageLabel, 'yes');
	}
	getShowAiCategoryFlowGoogle() {
		return localStorage.getItem(this.googleAiCategoryLocalStorageLabel);
	}
}
