import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.dev3';
import { ISignIn, ISignInResponse, ISignUp, IUpdatePassword } from '../models/auth-v2';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as AuthApi from '../models/authApi';
import * as FbApi from '../models/facebookApi';

@Injectable({
	providedIn: 'root'
})
export class AuthV2Service {
	constructor(private http: HttpClient) {}

	login(payload: ISignIn): Observable<ISignInResponse> {
		return this.http.post<ISignInResponse>(AuthApi.login, payload);
	}
	signUp(payload: ISignUp): Observable<ISignInResponse> {
		return this.http.post<ISignInResponse>(AuthApi.signup, payload);
	}

	resetPassword(payload: { Email: string }): Observable<any> {
		return this.http.post(AuthApi.resetPassword, payload);
	}
	// updatePassword(payload: IUpdatePassword): Observable<any> {
	// 	return this.http.post(this.baseUrl + 'reset-password', payload);
	// }

	isUserLogsIn() {
		return !!localStorage.getItem('decodedJwtIo');
	}

	clearLocalStorage() {
		localStorage.removeItem('token');
		localStorage.removeItem('decodedJwtIo');
	}
}
