import { Component, OnInit } from '@angular/core';
import { SidemenuService } from '../sidemenu/services/sidemenu.service';
import { FacebookDataService } from '../shakespeare/ads/facebook/facebook-data.service';
import { FakeBackendService } from '../_services/fake-backend.service';
import { SharedDropdownService } from '../shared-dropdown/service/shared-dropdown.service';
import { of } from 'rxjs';
import { AdsService } from '../shakespeare/ads/ads.service';

@Component({
	selector: 'app-test',
	templateUrl: './test.component.html',
	styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
	constructor(
		private sideMenu: SidemenuService,
		private fbService: FacebookDataService,
		private fakeService: FakeBackendService,
		public sharedDropdown: SharedDropdownService,
		private ads: AdsService
	) {}
	toggleMenu = true;
	config: any = {};
	config1: any = {};

	ngOnInit(): void {
		console.log(this.fbService.fbAccounts);

		console.log(this.getFbData());

		this.config.label = 'Ad Account';
		this.config.multi = false;
		this.config.type = 'adAccount';
		this.config.data = this.fbService.loadFbAdAccount$;
		this.config1.label = 'AI Category';
		this.config1.multi = false;
		this.config1.type = 'aiCategory';
		// this.config1.data = this.ads.getCategoriesIDsOnDashboard(this.fbService.fbAccounts[0].id);
	}
	handle() {
		this.toggleMenu = !this.toggleMenu;
		this.sideMenu.toggleSideMenu.next(this.toggleMenu);
	}

	getFbData() {
		this.fakeService.getFbAccount().subscribe(res => console.log(res));
	}
	handleDropDown(e) {
		console.log(e, 'from test component all dropdowns loaded');
	}
}
