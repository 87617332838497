import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as paymentPlan from '../models/payment-planApi';
@Injectable({
	providedIn: 'root'
})
export class PaymentPlanService {
	constructor(private http: HttpClient) {}

	getPlans() {
		return this.http.get(paymentPlan.plan);
	}

	subscribePlan(payload) {
		return this.http.post(paymentPlan.subscribePlan, payload);
	}
	updatePlan(payload) {
		return this.http.post(paymentPlan.subscribePlan, payload);
	}

	updateAdSpent() {
		return this.http.put(paymentPlan.updateAdSpent, {});
	}
	allowMoreAdSpent() {
		return this.http.get(paymentPlan.allowMoreSpent);
	}

	cancelPlan(payload) {
		return this.http.post(paymentPlan.planCancel, payload);
	}
	getCurrentSubscription() {
		return this.http.get(paymentPlan.currentSubscription);
	}
}
