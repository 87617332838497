import { environment } from 'src/environments/environment.dev3';

const subscription = environment.BaseUrl + 'subscription/';
export const plan = subscription + 'plans';
export const subscribePlan = subscription + 'subscribe';
export const updatePlan = subscription + 'update-plan';
export const updateAdSpent = subscription + 'update-ad-spend';
export const allowMoreSpent = subscription + 'allow-more-ad-spend';
export const planCancel = subscription + 'cancel';
export const currentSubscription = subscription + 'current';
