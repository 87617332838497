/// <reference types="chrome"/>
import { Component, NgZone, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationState, getRedirectUrl } from '../state/authentication.reducer';
import { select, Store } from '@ngrx/store';
import { LogIn, LogInSuccess } from '../state/authentication.action';
import { IBigCommerceAuth, LogInModel } from '../_models/login.model';
import { ValidatorMessages } from '../../shared/form-input/validatorMessagesInputs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AppState } from 'src/app/state/app.state';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { EMPTY, of, Subject } from 'rxjs';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { environment } from 'src/environments/environment';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { TokenService } from 'src/app/_services/token.service';
import { PaymentService } from 'src/app/user-management/components/payment.service';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { E } from '@angular/cdk/keycodes';
import { error } from 'console';

declare var google: any;

@Component({
	selector: 'app-signin',
	templateUrl: './signin-new.component.html',
	styleUrls: ['./signin-new.component.scss']
})
export class SigninComponent implements OnInit, AfterViewInit, OnDestroy {
	public signInForm: FormGroup;
	public emFormControl: FormControl;
	public passFormControl: FormControl;
	public isAccountSignedIn = false;
	public loading = false;
	public hasRedirectParam: boolean;
	public validationMessages: ValidatorMessages[];
	public isFromBigCommerce = false;
	private bigCommerceObj: IBigCommerceAuth;
	private unsubscriber$ = new Subject<void>();
	private emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	private googleClientId: string;
	googleAllowed: any = false;

	constructor(
		private router: Router,
		private tokenService: TokenService,
		private activatedRoute: ActivatedRoute,
		private authStore: Store<AuthenticationState>,
		private store: Store<AppState>,
		private paymentService: PaymentService,
		private authenticationService: AuthenticationService,
		private userService: UserServiceApi,
		private toastNotificationService: ToastNotificationService,
		public dialog: MatDialog,
		private ngZone: NgZone,
		private backOfficeApiService: BackOfficeService,
		private sharedStore: Store<SharedState>,
		private ref: ChangeDetectorRef
	) {
		// this.authStore.pipe(select(getRedirectUrl), take(1)).subscribe(redirectUrl => {
		// 	this.activatedRoute.queryParams.subscribe(params => {
		// 		if (params['src']) {
		// 			this.setCookie('loginSrc', params['src']);
		// 		} else {
		// 			// remove cookie by setting expiry date to past
		// 			document.cookie = 'loginSrc' + '=' + '' + ';' + 'Thu, 01 Jan 1970 00:00:01 GMT' + ';path=/';
		// 		}
		// 	});
		// });
		this.googleClientId = environment.GOOGLE_CLIENT_ID;
	}

	setCookieAcrossDomains(name: string, value: any): void {
		const date = new Date();
		// set expire in 1 day
		date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
		const expires = '; expires=' + date.toUTCString();
		// with domain and path
		document.cookie = name + '=' + (value || '') + expires + '; domain=.shakespeare.ai; path=/';
	}

	public ngOnInit(): void {
		// this.googleInitializer();
		// this.emFormControl = new FormControl();
		this.emFormControl = new FormControl('', {
			validators: [Validators.required, Validators.pattern(this.emailValidator), Validators.maxLength(70)],
			updateOn: 'change'
		});
		this.passFormControl = new FormControl('', [Validators.required]);

		this.signInForm = new FormGroup({
			email: this.emFormControl,
			password: this.passFormControl
		});

		// if (this.hasRedirectParam && !this.isFromBigCommerce) {
		// 	this.authStore.dispatch(new LogIn());
		// }

		this.toggleLoader();
	}

	handleExtensionRedirection(tabId: string, extensionId: string): void {
		let token = localStorage.getItem(StorageKey.token);
		let decodedJwtIo = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		if (token) {
			chrome.runtime.sendMessage(extensionId, {
				token,
				tabId,
				user_filed_id: decodedJwtIo.user_filed_id,
				decodedJwtIo
			}); // sending token to extension
			window.close();
		} else {
			// if user is not logged in
			this.authenticationService.extensionTabId$.next(tabId);
			this.authenticationService.extensionId$.next(extensionId);
			this.authenticationService.redirectToExtension$.next(true);
		}
	}

	ngAfterViewInit(): void {
		// this.googleInitializer();
		// this.activatedRoute.queryParams.subscribe(params => {
		// 	if (params['login_type'] === 'extension') {
		// 		this.handleExtensionRedirection(params['tabId'], params['extensionId']);
		// 	}
		// 	this.googleAllowed = params['app'] || 'false';
		// 	if (this.googleAllowed == 'v2') {
		// 		localStorage.setItem('googleAllowed', 'true');
		// 		this.setCookieAcrossDomains('googleAllowed', 'true');
		// 	} else {
		// 		localStorage.setItem('googleAllowed', 'false');
		// 		this.setCookieAcrossDomains('googleAllowed', 'false');
		// 	}
		// });
	}

	public googleInitializer(): void {
		// google?.accounts?.id?.initialize({
		// 	client_id: this.googleClientId,
		// 	callback: (response: any) => this.signinWithGoogle(response),
		// 	ux_mode: 'popup',
		// 	prompt: 'select_account'
		// });
		// google?.accounts?.id.renderButton(
		// 	document.getElementById('google-btn'),
		// 	{ size: 'rectangular', width: '430' }
		// );
		// window.onload = () => {
		google.accounts.id.initialize({
			client_id: this.googleClientId,
			callback: res => this.signinWithGoogle(res)
		});
		google.accounts.id.renderButton(
			document.getElementById('google-btn'),
			{ theme: 'outline', size: 'large' } // customization attributes
		);
		google.accounts.id.prompt(); // also display the One Tap dialog
		// };
	}

	public getCookie(name: string) {
		let ca: Array<string> = document.cookie.split(';');
		let caLen: number = ca.length;
		let cookieName = `${name}=`;
		let c: string;

		for (let i: number = 0; i < caLen; i += 1) {
			c = ca[i].replace(/^\s+/g, '');
			if (c.indexOf(cookieName) == 0) {
				return c.substring(cookieName.length, c.length);
			}
		}
		return '';
	}

	public setCookie(cname, cvalue) {
		// far future expiry date
		let expires = 'expires=' + new Date(2147483647 * 1000).toUTCString();
		document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
	}

	public signinWithGoogle(token): void {
		this.setCookie('from', 'Google');
		this.loading = true;
		this.ref.detectChanges();
		if (token) {
			this.userService
				.googleSignin(token.credential)
				.pipe(
					takeUntil(this.unsubscriber$),
					catchError(error => {
						this.loading = false;
						this.toastNotificationService.sendErrorToast('User does not exist, please register with your id');
						this.googleInitializer();
						return of(null);
					})
				)
				.subscribe(value => {
					this.loading = false;
					this.ngZone.run(() => {
						this.store.dispatch(new LogInSuccess(value));
					});
					this.toggleLoader();
				});
		} else {
			this.loading = false;
			this.googleInitializer();
			this.toastNotificationService.sendErrorToast('Error while signing you in with google, please contact support team');
		}
	}

	public onForgotPasswordClick(): void {
		this.router.navigate(['/authentication/reset-password-with-email']);
	}

	public emptyFormFields(): boolean {
		return this.signInForm.controls.email.value === true || this.signInForm.controls.password.value === true;
	}

	public isEmailPasswordValid(): boolean {
		this.signInForm.setControl(
			'email',
			new FormControl(this.signInForm.controls.email.value, {
				validators: [Validators.required, Validators.pattern(this.emailValidator), Validators.maxLength(70)],
				updateOn: 'change'
			})
		);
		return this.signInForm.controls.email.valid && this.signInForm.controls.password.valid;
	}

	public onSubmit(): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		if (!this.isEmailPasswordValid() || this.emptyFormFields()) {
			this.signInForm.markAllAsTouched();
			this.sharedStore.dispatch(new ShowGlobalSpinner());
			return;
		}

		this.loading = true;

		const temporaryLoginEmail = this.signInForm.controls.email.value.trim();
		const temporaryLoginPassword = this.signInForm.controls.password.value.trim();

		if (!this.hasRedirectParam) {
			const temporaryCredentials = {
				emailAddress: this.signInForm.controls.email.value.trim(),
				password: this.signInForm.controls.password.value.trim()
			};
		}

		const loginPayload: LogInModel = {
			email: temporaryLoginEmail,
			password: temporaryLoginPassword,
			code: ''
		};
		this.setCookie('from', 'platform');
		this.userService.login(loginPayload.email, loginPayload.password).subscribe(
			(res: any) => {
				console.log(res);
				const token = res.access_token;
				localStorage.setItem(StorageKey.token, token);
				const decodedJwtIo = this.tokenService.decodeToken(token);
				localStorage.setItem(StorageKey.decodedJwtIo, JSON.stringify(decodedJwtIo));
				// this.router.navigateByUrl('');
			},
			error => {
				console.log(error);
			}
		);
		// if (!this.isFromBigCommerce) {
		// 	this.ngZone.run(() => {
		// 		this.authStore.dispatch(new LogIn(loginPayload));
		// 	});
		// 	this.toggleLoader();
		// } else if (this.isFromBigCommerce) {
		// 	this.bigCommerceObj.emailAddress = temporaryLoginEmail;
		// 	this.bigCommerceObj.password = temporaryLoginPassword;
		// 	this.authBigCommerceUser(this.bigCommerceObj);
		// }
	}

	private toggleLoader(): void {
		// this.store.select('authentication').subscribe(res => {
		// 	if (res?.jwtToken?.data) {
		// 		this.tokenService.decodeToken(res.jwtToken.data);
		// 		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		// 		if (token && token.sign_up_version && token.sign_up_version == 'v2') {
		// 			localStorage.setItem('googleAllowed', 'true');
		// 		} else {
		// 			localStorage.setItem('googleAllowed', 'false');
		// 		}
		// 	}
		// 	if (!res.isAuthenticated) {
		// 		this.loading = false;
		// 		this.sharedStore.dispatch(new HideGlobalSpinner());
		// 	}
		// });
	}

	private authBigCommerceUser(payload: IBigCommerceAuth) {
		this.userService
			.bigCommerceAuth(payload)
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.loading = false;
					this.toastNotificationService.sendErrorToast('There was an error authenticating user for big commerce ' + error.toString());
					return EMPTY;
				})
			)
			.subscribe(value => {
				if (value.status === 200) {
					const loginPayload: LogInModel = {
						email: payload.emailAddress,
						password: payload.password,
						code: ''
					};
					this.authenticationService.bigCommerceAction$.next(true);
					this.authStore.dispatch(new LogIn(loginPayload));
					this.toggleLoader();
				} else {
					this.loading = false;
					this.toastNotificationService.sendErrorToast('There was an error authenticating user for big commerce ');
				}
			});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	register() {
		this.router.navigate(['authentication/register-email']);
	}
}
