import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ResetPasswordEmailDialogComponent } from './reset-password-email/reset-password-email-dialog.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { SetPasswordComponent } from './set-password/set-password.component';
import { SigninComponent } from './signin/signin.component';
import { TokenService } from '../_services/token.service';
import { FirstLoginDialogComponent } from './first-login-dialog/first-login-dialog.component';
import { SharedModule } from '../shared/shared.module';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { authenticationReducer } from './state/authentication.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticationEffects } from './state/authentication.effects';
import { GoogleLandingPageComponent } from './landing-pages/google-landing-page/google-landing-page.component';
import { FacebookLandingPageComponent } from './landing-pages/facebook-landing-page/facebook-landing-page.component';
import { ResetPasswordWithEmailComponent } from './reset-password-with-email/reset-password-with-email.component';
import { InterceptorsProvider } from '../_services/interceptors/intercepors.provider';
// import { PaymentNewComponent } from './payment-new/payment-new.component';
// import { FaqComponent } from './payment-new/components/faq/faq.component';
// import { FeaturesComponent } from './payment-new/components/features/features.component';

@NgModule({
	imports: [
		AuthenticationRoutingModule,
		TranslateModule,
		// FlexLayoutModule,
		SharedModule,
		RouterModule,
		StoreModule.forFeature('authentication', authenticationReducer),
		EffectsModule.forFeature([AuthenticationEffects])
	],
	declarations: [
		SigninComponent,
		SetPasswordComponent,
		ResetPasswordEmailDialogComponent,
		FirstLoginDialogComponent,
		FacebookLandingPageComponent,
		GoogleLandingPageComponent,
		ResetPasswordWithEmailComponent
		// PaymentNewComponent,
		// FaqComponent,
		// FeaturesComponent
	],
	exports: [SigninComponent, SetPasswordComponent, ResetPasswordEmailDialogComponent, FacebookLandingPageComponent, GoogleLandingPageComponent],
	providers: [TokenService, InterceptorsProvider],
	schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthenticationModule {}
