import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedDropdownComponent } from './shared-dropdown.component';
import { SharedModule } from '../shared/shared.module';
import { DropdownComponent } from './component/dropdown/dropdown.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
	declarations: [SharedDropdownComponent, DropdownComponent],
	imports: [CommonModule, SharedModule, NgxSkeletonLoaderModule],
	exports: [SharedDropdownComponent]
})
export class SharedDropdownModule {}
