<div class="dropdown-container">
	<ng-container *ngFor="let d of dropdownToShow">
		<app-dropdown
			(dropdownOutput)="handleDropdownSelectionChange($event)"
			*ngIf="d == platformConfig.key"
			[config]="platformConfig"
			[isLoading]="platformLoader"
		></app-dropdown>
		<app-dropdown
			(dropdownOutput)="handleDropdownSelectionChange($event)"
			*ngIf="d == adAccountConfig.key"
			[config]="adAccountConfig"
			[isLoading]="adAccountLoader"
		></app-dropdown>
		<app-dropdown
			(dropdownOutput)="handleDropdownSelectionChange($event)"
			*ngIf="d == AiCategoryConfig.key"
			[config]="AiCategoryConfig"
			[isLoading]="aiCategoryLoader"
		></app-dropdown>
		<app-dropdown
			(dropdownOutput)="handleDropdownSelectionChange($event)"
			*ngIf="d == adSetConfig.key"
			[config]="adSetConfig"
			[isLoading]="adSetLoader"
		></app-dropdown>
		<app-dropdown
			(dropdownOutput)="handleDropdownSelectionChange($event)"
			*ngIf="d == campaignConfig.key"
			[config]="campaignConfig"
			[isLoading]="campaignLoader"
		></app-dropdown>
	</ng-container>
</div>
