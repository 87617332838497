import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './http-config.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { ErrorPopupInterceptor } from './error-popup.interceptor';

export const InterceptorsProvider = [
	// {
	// 	provide: HTTP_INTERCEPTORS,
	// 	useClass: ErrorInterceptor,
	// 	multi: true
	// },
	{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpConfigInterceptor,
		multi: true
	}
	// {
	// 	provide: HTTP_INTERCEPTORS,
	// 	useClass: ErrorPopupInterceptor,
	// 	multi: true
	// }
];
