import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { SharedDropdownService } from '../../service/shared-dropdown.service';
import { map, tap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { AdsService } from 'src/app/shakespeare/ads/ads.service';

@Component({
	selector: 'app-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DropdownComponent implements OnInit {
	@Input() config!: any;
	@Input() optionData: any;
	@Input() isLoading: boolean;
	@Output() dropdownOutput = new EventEmitter();
	formModel: any;
	constructor(private sharedDropdownService: SharedDropdownService, private adsService: AdsService) {}
	valueSelected = new FormControl();
	ngOnChanges(changes: SimpleChanges): void {
		// console.log(changes);
		// console.log(changes.isLoading);
		if (changes?.isLoading?.currentValue) {
			this.isLoading = changes?.isLoading?.currentValue;
		}
	}
	ngOnInit(): void {}

	// selectControler = new FormControl(this.valueSelected);

	search(e) {
		let val = e.target.value;
		console.log(val);
		// this.filterCountries = this.countries.filter(v => {
		// 	return v?.full.toLowerCase().includes(val.toLowerCase());
		// });
	}

	loadOptions() {
		// this.filterCountries = this.sharedDropdown.loadFbAdAccount();
	}
	handleSelection(e) {
		console.log(e);
		let val = e.value;
		return this.dropdownOutput.emit({ config: this.config, value: val });
	}
}
