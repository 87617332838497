import { Injectable } from '@angular/core';
import * as layoutModel from '../model/model';
import { BehaviorSubject, delay, of } from 'rxjs';
import { HelperService } from 'src/app/shakespeare/ads/ai-category-flow-fb-google/services/helper.service';
import { StorageKey } from 'src/app/auth-v2/models/local-storage-key';
@Injectable({
	providedIn: 'root'
})
export class LayoutService {
	platform = layoutModel.platform.fullAccess;
	isGoogleConnected = false;
	isFacebookConnected = false;
	planDetail = null;
	isProUser = false;

	initializeLayoutMethods = new BehaviorSubject(null);
	initializeLayoutMethods$ = this.initializeLayoutMethods.asObservable();
	constructor(private helperService: HelperService) {}

	getPlatform() {
		let platform = [
			{ id: 'google', name: 'Google' },
			{ id: 'facebook', name: 'Facebook' }
		];
		return of(platform).pipe(delay(1000));
	}
	getPlanDetail() {
		return this.helperService.getLocalStorage(StorageKey.planDetail);
	}

	setPlanDetail(plan) {
		this.helperService.setToLocalStorage(StorageKey.planDetail, plan);
	}

	getIsGoogleConnected() {
		return this.helperService.getLocalStorageSimple(StorageKey.isGoogleConnected);
	}
	getIsFacebookConnected() {
		return this.helperService.getLocalStorageSimple(StorageKey.isFbConnected);
	}
	setGoogleConnected() {
		this.isGoogleConnected = true;
		this.helperService.setLocalStorageSimple(StorageKey.isGoogleConnected, true);
	}
	setFbConnected() {
		this.isFacebookConnected = true;
		this.helperService.setLocalStorageSimple(StorageKey.isFbConnected, true);
	}
	removeGoogleConnected() {
		this.isGoogleConnected = null;
		this.helperService.setLocalStorageSimple(StorageKey.isGoogleConnected, false);
	}
	removeFacebookConnected() {
		this.isFacebookConnected = null;
		this.helperService.setLocalStorageSimple(StorageKey.isFbConnected, false);
	}
}
