import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject, Subscription, interval } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NotificationsService } from '../shared/services/notifications.service';
import { Router } from '@angular/router';
import { KnowledgebaseComponent } from '../sidenav/component-pieces/knowledgebase/knowledgebase.component';
import { MatDialog } from '@angular/material/dialog';
import { BackOfficeService } from '../_services/back-office/back-office.service';
import { AdsService } from '../shakespeare/ads/ads.service';
import { SidemenuService } from './services/sidemenu.service';
import { ISidemenuOptionParent } from './models/sidemenu';
import { HelperService } from '../shakespeare/ads/ai-category-flow-fb-google/services/helper.service';
import { AuthV2Service } from '../auth-v2/services/auth-v2.service';

@Component({
	selector: 'app-sidemenu',
	templateUrl: './sidemenu.component.html',
	styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {
	isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
		map(result => result.matches),
		shareReplay()
	);

	private notificationSubscription: Subscription;
	public notifications: any[] = [];
	public unreadCount: number = 0;
	public notificationPollInterval: number = 10000;
	private unsubscriber$ = new Subject<void>();
	handleProfileToggle = false;
	firstTimeUser = false;
	companyDetails: any;
	userDetailsData: any;
	profileOpen = false;
	selectedElement = 0;
	sideMenuOptions: ISidemenuOptionParent[];
	userDetail;
	isSidemenuOpened = true;
	constructor(
		private breakpointObserver: BreakpointObserver,
		private notificationService: NotificationsService,
		private router: Router,
		private dialog: MatDialog,
		private backOfficeService: BackOfficeService,
		private adsService: AdsService,
		public sideMenuService: SidemenuService,
		private helperService: HelperService,
		private authService: AuthV2Service
	) {}
	ngOnInit(): void {
		this.sideMenuOptions = [...this.sideMenuService.sideMenuOptions];
		this.userDetail = this.helperService.getUser();
		this.sideMenuService.toggleSideMenu$.subscribe(res => {
			this.isSidemenuOpened = res;
		});
		this.menuSelectProgramatically();
	}
	menuSelectProgramatically() {
		this.sideMenuOptions = this.sideMenuService.sideMenuOptions.map(v => {
			if (v.hasChild) {
				v.child = v.child.map(g => {
					if (g.route == location.pathname) {
						this.selectedElement = g.id;
						v.expand = true;
					}
					return g;
				});
				return v;
			}
			return v;
			// if (v.id == 2 && location.pathname == '/layout/ads/facebook/dashboard') {
			// 	this.selectedElement = v.child[0].id;
			// 	v.expand = true;
			// }
			// return v;
		});
		console.log(this.sideMenuOptions, 'updated sidemenu');
		// this.selectedElement = item.id;
	}
	public updateUnreadCountEvent(count: number): void {
		this.unreadCount = count;
	}

	get isShowAllNotification() {
		return this.router.url == '/notifications';
	}

	public pollNotifications(): void {
		interval(this.notificationPollInterval)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(x => {
				if (!this.isShowAllNotification) this.getNotifications();
			});
	}

	public getNotifications(): void {
		// this.removeNotificationPoll();
		// this.notificationSubscription = this.notificationService
		// 	.getAllNotifications()
		// 	.pipe(take(1))
		// 	.subscribe(res => {
		// 		this.notifications = res;
		// 		this.unreadCount = res?.filter(i => i.unreadRead).length;
		// 	});

		if (!this.isShowAllNotification) {
			this.notificationService.getAllNotifications().subscribe(res => {
				this.notifications = res;
				// this.unreadCount = res?.filter(i => i.unreadRead).length;
			});
		}
	}

	public removeNotificationPoll(): void {
		if (this.notificationSubscription && !this.notificationSubscription.closed) {
			this.notificationSubscription.unsubscribe();
		}
	}

	public openKnowledgeBase(isMobile = false): void {
		if (this.firstTimeUser) {
			return;
		}
		this.dialog.open(KnowledgebaseComponent, {
			width: isMobile ? '100%' : '400px',
			height: '100%',
			position: {
				right: '0'
			},
			panelClass: ['matdialog-no-padding', 'slide', 'slideInRight']
		});
	}
	sideMenuToggle(toggle) {
		this.sideMenuService.toggleSideMenu.next(!toggle);
	}

	userData() {
		this.backOfficeService
			.getUserDetails()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(response => {
				if (response) {
					this.userDetailsData = response;
				}
			});
	}
	company() {
		this.adsService.company().subscribe((res: any) => {
			this.companyDetails = res.data;
		});
	}
	goHome() {
		this.sideMenuService.toggleSideMenu.next(true);
		this.helperService.navigate('/layout/overview');
	}
	toggleProfileAction(): void {
		if (this.firstTimeUser) {
			return;
		}
		this.profileOpen ? (this.profileOpen = false) : (this.profileOpen = true);
	}

	handleSideMenuOption(item, parentIndex, childIndex) {
		console.log(parentIndex, childIndex, item.id, this.selectedElement);
		this.selectedElement = item.id;
		console.log(this.selectedElement == item.id);
		this.helperService.navigate(item.route);
	}
	trackOption(index, option) {
		return option ? option.id : undefined;
	}
	goToSetting() {
		this.helperService.navigate('layout/settings/profile');
	}
	signOut() {
		this.authService.clearLocalStorage();
		this.helperService.navigate('/login');
	}
}
