import { Injectable } from '@angular/core';
import { HelperService } from '../ai-category-flow-fb-google/services/helper.service';
import { AdsService } from '../ads.service';
import { SkeletonLoaderService } from '../ai-category-flow-fb-google/services/skeleton-loader.service';
import { BehaviorSubject, Subject, distinctUntilChanged, map } from 'rxjs';
import { IAdAccount } from './model/facebook';
import * as FbApi from '../../../auth-v2/models/facebookApi';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class FacebookDataService {
	dashboardAiCategory: any;
	dashboardGetFbGroupsIDs: any;
	getFbCardScoreAndDetails: any;
	dashboardgetSelectedAdaccountFbCategoriesIDs: any;
	dashboardaddFbAccounts: any;
	dashboardadFbAccountCurrency: any;
	localstorageFbCategoryId: any;
	localstorageFbAccountId: any;
	loadFbAdAccount = new Subject();
	loadFbAdAccount$ = this.loadFbAdAccount.asObservable();
	fbAccounts: IAdAccount[];
	fbAiCategory = [];
	fbCampaigns: any;
	constructor(
		private helperService: HelperService,
		private adsService: AdsService,
		private skeletonService: SkeletonLoaderService,
		private http: HttpClient
	) {}

	createVaraintClicked(data) {
		let json = {
			descriptions: [],
			headlines: [],
			primary_text: [],
			company_description: ''
		};
		json.descriptions.push(data.parentData.description);
		json.headlines.push(data.parentData.headline);
		json.primary_text.push(data.parentData.primaryText);
		let fbObj = {
			creativeId: data.adData.creativeId,
			adGroupId: data.adData.adGroupId,
			adId: data.adData.adId,
			pageId: data.adData.creative?.objectStorySpec?.pageId,
			displayUrl: this.getUrl(data, 'displayUrl'),
			websiteUrl: this.getUrl(data, 'websiteUrl'),
			adName: data.adData.adName,
			pageName: '',
			logoUrl: '',
			cta: this.checkCallToAction(data.adData.creative) || '',
			creative: data.adData.creative
		};
		this.helperService.setToLocalStorage(this.helperService.localStrogeKeys.fbModuleObject, fbObj);
		this.adsService.generateImageVariantText(json).subscribe(res => {
			console.log(res);
			this.helperService.setToLocalStorage(this.helperService.localStrogeKeys.imageVariantText, res);
			this.cardVariantSelected();
		});
	}

	generateMore(data) {
		let json = {
			descriptions: [],
			headlines: [],
			primary_text: [],
			company_description: ''
		};
		json.descriptions.push(data.description);
		json.headlines.push(data.headline);
		json.primary_text.push(data.primary_text);
		return this.adsService.generateImageVariantText(json);
	}

	getUrl(data, type) {
		if (data.adData.creative?.assetFeedSpec) {
			if (data.adData.creative?.assetFeedSpec?.link_url) {
				return data.adData.creative?.assetFeedSpec?.linkUrls[0][type];
			} else {
				return '';
			}
		} else {
			return '';
		}
	}

	cardVariantSelected() {
		let fbModuleObj = this.helperService.getLocalStorage(this.helperService.localStrogeKeys.fbModuleObject);
		let pageId = fbModuleObj.pageId;
		let creativeId = fbModuleObj.creativeId;
		this.adsService.fbpageDetail(pageId, creativeId).subscribe((res: any) => {
			fbModuleObj.pageName = res.pageName;
			fbModuleObj.logoUrl = res.logoUrl;
			this.helperService.setToLocalStorage(this.helperService.localStrogeKeys.fbModuleObject, fbModuleObj);
		});
	}
	formatCTA(value) {
		try {
			if (value.indexOf('_') != -1) {
				return (value = value.split('_').join(' '));
			}

			return value;
		} catch (e) {
			return '';
		}
	}

	checkCallToAction(data) {
		let cta = '';
		try {
			cta = data.assetFeedSpec?.callToActionTypes || [];
			if (cta.length) cta = cta[0];
			else cta = data?.callToActionType;
		} catch (e) {
			return '';
		}
		return this.formatCTA(cta);
	}

	updateFacebookAccessToken(payload) {
		return this.http.put(FbApi.updateAccessToken, payload);
	}
	validateFacebookAccessToken() {
		return this.http.get(FbApi.validateAccessToken);
	}
	getFbAccount() {
		return this.http.get(FbApi.getAccounts).pipe(
			map(res => {
				res['ad_accounts'] = res['ad_accounts'].map(v => {
					v.id = v.id.slice(4);
					return v;
				});
				return res;
			})
		);
	}

	getFbCampaign(fbAccountId: string) {
		let url = FbApi.getCampaign.replace('{accountId}', fbAccountId);
		return this.http.get(url).pipe(distinctUntilChanged());
	}
	getFbAdSet(campaignId) {
		let url = FbApi.adSets.replace('{campaignId}', campaignId);
		return this.http.get(url).pipe(distinctUntilChanged());
	}

	fbDisconnect() {
		return this.http.put(FbApi.fbDisconnect, {});
	}

	setFbAccount() {}
}
