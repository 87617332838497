import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class HelperService {
	aiCategoryFlow: any = {};
	localStrogeKeys = {
		createAiCategoryFlow: 'createAiCategoryFlow',
		decodedJwtIo: 'decodedJwtIo',
		planDetails: 'planDetails',
		imageVariantText: 'imageVariantText',
		selectedCardVariant: 'selectedCardVariant',
		FbAccountId: 'FbAccountId',
		FbCategoryId: 'FbCategoryId',
		FbAdGroupId: 'FbAdGroupId',
		FbAdId: 'FbAdId',
		FbCreativeId: 'creativeId',
		fbAdName: 'fbAdName',
		fbPageId: 'fbPageId',
		fbModuleObject: 'fbModuleObject',
		googleAdPayload: 'googleAdPayload'
	};
	constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

	setToLocalStorage(key, value) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	getLocalStorage(key) {
		let value = localStorage.getItem(key);

		return value ? JSON.parse(value) : null;
	}
	removeFromLocal(key) {
		localStorage.removeItem(key);
	}

	getfileId() {
		return this.getLocalStorage('decodedJwtIo').user_filed_id.toString();
	}
	setLocalStorageSimple(key, value) {
		localStorage.setItem(key, value);
	}
	getLocalStorageSimple(key) {
		return localStorage.getItem(key);
	}

	getFbAdAccountId() {
		return localStorage.getItem(this.localStrogeKeys.FbAccountId) || null;
	}
	getFbCategoryId() {
		return localStorage.getItem(this.localStrogeKeys.FbCategoryId) || null;
	}
	insertSpaces(string) {
		string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
		string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
		return string;
	}

	getUser() {
		return this.getLocalStorage(this.localStrogeKeys.decodedJwtIo) || null;
	}

	removeWhiteSpaces(string) {
		return string.replace(/\s/g, '').trim();
	}
	navigate(url): void {
		this.router.navigateByUrl(url);
	}
	getQueryParam(query: string): string {
		return this.activatedRoute.snapshot.queryParamMap.get(query);
	}
}
