import { Injectable } from '@angular/core';
import { delay, of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class FakeBackendService {
	constructor() {}

	fbAdAccount = [
		{
			account_status: 1,
			facebookUser: 'nehalgraphicsweb@gmail.com',
			name: 'shakespeare_2023-07-27 18:56:41.183659_creative',
			id: 'act_389109158588065',
			currency: 'GBP'
		},
		{
			account_status: 1,
			facebookUser: 'nehalgraphicsweb@gmail.com',
			name: 'Shakespeare',
			id: 'act_1028557708526082',
			currency: 'USD'
		},
		{
			account_status: 1,
			facebookUser: 'nehalgraphicsweb@gmail.com',
			name: 'Nehal Gokhale',
			id: 'act_3653648691589223',
			currency: 'INR'
		},
		{
			account_status: 1,
			facebookUser: 'nehalgraphicsweb@gmail.com',
			name: 'GHS Kotbagi',
			id: 'act_746377467445142',
			currency: 'INR'
		}
	];

	fbAdSet = {
		account_status: 0,
		status: 'ACTIVE',
		name: 'Platform Demo',
		id: '120202183267090728'
	};

	fbCampaigns = [
		{
			account_status: 0,
			status: 'ACTIVE',
			name: 'Lead Form_Demo_USA',
			id: '120202183266950728'
		}
	];

	fbAiCategory = [
		{
			adAccountId: '389109158588065',
			aiCategoryId: '38589dcb-1ac2-448f-a21d-357bf48d4c7d',
			createdAt: '2024-02-16 06:02:00.794801',
			name: 'SHPR_DEMO',
			numAdgroups: 1
		},
		{
			adAccountId: '3653648691589223',
			aiCategoryId: '5a37aecd-0ee9-44b1-8fa8-bd9a0b2c599c',
			createdAt: '2024-02-16 06:12:24.920810',
			name: 'FEB 16_Demo_Nehal',
			numAdgroups: 1
		},
		{
			adAccountId: '3653648691589223',
			aiCategoryId: '77bd3b30-631e-4feb-8e86-9dd4a0c4bdc5',
			createdAt: '2024-02-16 06:34:55.671818',
			name: 'hello',
			numAdgroups: 1
		},
		{
			adAccountId: '389109158588065',
			aiCategoryId: 'ab93a500-5da9-4978-bb4d-6f2eb3d9bcd9',
			createdAt: '2024-03-15 06:02:59.729790',
			name: 'jhgh',
			numAdgroups: 1
		}
	];

	getFbAccount() {
		return of(this.fbAdAccount).pipe(delay(5000));
	}
}
