<!-- <div [innerHTML]="html"></div> -->
<ng-container *ngIf="!isLoading">
	<app-sidemenu>
		<!-- <div [class.hide]="!loading" class="spinner-wrapper" *ngIf="loading">
        <shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
    </div> -->

		<router-outlet></router-outlet>
	</app-sidemenu>
</ng-container>
