import { AfterViewInit, Component, OnInit } from '@angular/core';
import { OverviewApiService } from '../services/overview-api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OverviewDataService } from '../services/overview-data.service';
import { MatDialog } from '@angular/material/dialog';
import { TourPopupComponent } from '../components/tour-popup/tour-popup.component';
import { ShakespeareService } from 'src/app/shakespeare/shakespeare.service';
import moment from 'moment';
import { FacebookDataService } from 'src/app/shakespeare/ads/facebook/facebook-data.service';
import { GoogleDataService } from 'src/app/shakespeare/ads/google/services/google-data.service';
import { HelperService } from 'src/app/shakespeare/ads/ai-category-flow-fb-google/services/helper.service';

@Component({
	selector: 'app-overview',
	templateUrl: './overview.component.html',
	styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
	localStorageValue: any = JSON.parse(localStorage.getItem('decodedJwtIo'));
	overallDataList: any;
	overviewFacebookDataList: any;
	googleADaccountData: any;
	facebookADaccountData: any;
	showLoader: boolean = false;
	fromDate: any;
	toDate: any;
	// range: FormGroup;
	public otherData: any = {
		optimize: {
			google: {},
			facebook: {}
		},
		creative: {
			google: {},
			facebook: {}
		}
	};
	public viewAllGoogle: boolean = false;
	public viewAllFacebook: boolean = false;
	public creativeLoader: boolean = false;
	public optimizeLoader: boolean = false;
	public creativeFbLoader: boolean = false;
	public optimizeFbLoader: boolean = false;

	public calendarStartDate: any;
	public calendarEndDate: any;

	constructor(
		private overviewApiService: OverviewApiService,
		private router: Router,
		private fb: FormBuilder,
		private overviewDataService: OverviewDataService,
		private dialog: MatDialog,
		private shakespeareService: ShakespeareService,
		private fbService: FacebookDataService,
		private googleService: GoogleDataService,
		private helperService: HelperService
	) {
		this.calendarStartDate = moment();
		this.calendarEndDate = moment();

		const oneMonthAgo = new Date();
		oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
		// this.range = this.fb.group({
		// 	start: [oneMonthAgo],
		// 	end: [new Date()]
		// });

		// this.range.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
		// 	this.getOverViewCards();
		// });
	}

	ngOnInit(): void {
		// this.shakespeareService.waitforAdAccountToLoad$.subscribe(res => {
		// 	if (res == '1') {
		// 		console.log('this is working');
		// 	}
		// });

		this.initializeData();
	}

	initializeData() {
		// if (this.overviewDataService.googleAdAccount && this.overviewDataService.overviewGoogleDataList) {
		// 	this.googleADaccountData = JSON.parse(JSON.stringify(this.overviewDataService.googleAdAccount));
		// 	this.overallDataList = JSON.parse(JSON.stringify(this.overviewDataService.overviewGoogleDataList));
		// } else {
		// 	this.getGoogleAdAccount();
		// }

		// if (this.overviewDataService.facebookAdAccount && this.overviewDataService.overviewFacebookDataList) {
		// 	this.facebookADaccountData = JSON.parse(JSON.stringify(this.overviewDataService.facebookAdAccount));
		// 	this.overviewFacebookDataList = JSON.parse(JSON.stringify(this.overviewDataService.overviewFacebookDataList));
		// } else {
		// 	this.getFacebookAdAccount();
		// }

		// if first time show welcome tour
		// this.showTourPopup();
		// this.getGoogleAdAccount();
		this.getFacebookAdAccount();
		let isFirsttime = localStorage.getItem('showTourPopup');
		if (isFirsttime) {
			this.showTourPopup();

			localStorage.removeItem('showTourPopup');
		}
	}

	private getGoogleOtherData(): void {
		this.optimizeLoader = true;
		this.creativeLoader = true;

		this.overviewApiService
			.getGoogleOptimizeData()
			.subscribe(res => {
				// console.log('google', res);
				const optimizeData = res?.data;
				if (optimizeData) {
					this.otherData.optimize.google = optimizeData;
				}
			})
			.add(() => {
				this.optimizeLoader = false;
			});

		this.overviewApiService
			.getGooglePerformanceData()
			.subscribe(res => {
				// console.log('google', res);
				const performanceData = res?.data;
				if (performanceData) {
					this.otherData.creative.google = performanceData;
				}
			})
			.add(() => {
				this.creativeLoader = false;
			});
	}

	private getFacebookOtherData(): void {
		this.optimizeFbLoader = true;
		this.creativeFbLoader = true;

		this.overviewApiService
			.getFacebookOptimizeData()
			.subscribe(res => {
				// console.log('facebook', res);
				const optimizeData = res?.data;
				if (optimizeData) {
					this.otherData.optimize.facebook = optimizeData;
				}
			})
			.add(() => {
				this.optimizeFbLoader = false;
			});

		this.overviewApiService
			.getFacebookPerformanceData()
			.subscribe(res => {
				// console.log('facebook', res);
				const performanceData = res?.data;
				if (performanceData) {
					this.otherData.creative.facebook = performanceData;
				}
			})
			.add(() => {
				this.creativeFbLoader = false;
			});
	}

	showTourPopup() {
		this.dialog.open(TourPopupComponent, { width: '450px', height: '360px' });
	}

	getOverViewCards() {
		this.showLoader = true;
		let payload = {
			user: {
				user_filed_id: this.localStorageValue.user_filed_id.toString()
			},
			data: {
				num_accounts: 'ALL',
				date_range: {
					from_date: this.fromDate,
					to_date: this.toDate
				}
			}
		};
		this.overviewApiService.getCardsData(payload).subscribe(res => {
			this.overallDataList = res.data;
			this.mergeAdAccountwithoverallDataList();
		});
	}

	getGoogleAdAccount() {
		this.googleADaccountData = this.googleService.googleAdAccount;
		this.overviewDataService.googleAdAccount = this.googleService.googleAdAccount;
		this.getGoogleOtherData();
		this.mergeAdAccountwithoverallDataList();
		// this.overviewApiService.getGoogleAdAccount().subscribe(
		// 	res => {
		// 		this.googleADaccountData = res;
		// 		this.overviewDataService.googleAdAccount = JSON.parse(JSON.stringify(res));

		// 	},
		// 	err => {
		// 		console.log(err);
		// 		this.showLoader = false;
		// 	}
		// );
	}

	mergeAdAccountwithoverallDataList() {
		if (this.overallDataList && this.googleADaccountData) {
			for (let ad of this.googleADaccountData) {
				for (let ovl of this.overallDataList) {
					if (ad.id == ovl.adAccountId) {
						ovl.adAccountName = ad.name;
						ovl.currency = ad.currency;
						ovl.currencySymbol = this.overviewDataService.currencySymbol[ad.currency];
					}
				}
			}

			this.showLoader = false;
			this.overviewDataService.overviewGoogleDataList = JSON.parse(JSON.stringify(this.overallDataList));
		}
	}

	getFacebookAdAccount() {
		this.overviewDataService.facebookAdAccount = this.fbService.fbAccounts;
		this.getFacebookOtherData();
		this.mergeFacebookAdAccountwithoverallDataList();
		// this.overviewApiService.getFacebookAdAccount().subscribe(
		// 	res => {
		// 		this.facebookADaccountData = res.data;
		// 		for (let item of this.facebookADaccountData) {
		// 			item.id = item.id.replace('act_', '');
		// 		}

		// 	},
		// 	err => {
		// 		console.log(err);
		// 		this.showLoader = false;
		// 	}
		// );
	}

	getFacebookOverViewCards() {
		let payload = {
			user: {
				user_filed_id: this.helperService.getfileId()
			},
			data: {
				num_accounts: 'ALL',
				date_range: {
					from_date: this.fromDate,
					to_date: this.toDate
				}
			}
		};
		this.overviewApiService.getFBCardsData(payload).subscribe(res => {
			this.overviewFacebookDataList = res.data;
			this.mergeFacebookAdAccountwithoverallDataList();
		});
	}

	mergeFacebookAdAccountwithoverallDataList() {
		if (this.overviewFacebookDataList && this.facebookADaccountData) {
			for (let ad of this.facebookADaccountData) {
				for (let ovl of this.overviewFacebookDataList) {
					if (ad.id == ovl.adAccountId) {
						ovl.adAccountName = ad.name;
						ovl.currency = ad.currency;
						ovl.currencySymbol = this.overviewDataService.currencySymbol[ad.currency];
					}
				}
			}
			this.showLoader = false;
			this.overviewDataService.overviewFacebookDataList = JSON.parse(JSON.stringify(this.overviewFacebookDataList));
		}
	}

	onViewAll(selectedPlatform: string) {
		if (selectedPlatform == 'google') {
			this.viewAllGoogle = true;
			// this.router.navigate(['overview/google-adAccount']);
		}
		if (selectedPlatform == 'facebook') {
			this.viewAllFacebook = true;
			// this.router.navigate(['overview/facebook-adAccount']);
		}
	}

	public goBack(): void {
		this.viewAllGoogle = false;
		this.viewAllFacebook = false;
	}

	formatDate(date: Date): string {
		// Get the date components in the "YYYY-MM-DD" format
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');

		return `${year}-${month}-${day}`;
	}

	initialiseStartDate(startDate: any) {
		this.fromDate = startDate;
	}

	initialiseEndDate(endDate: any) {
		this.toDate = endDate;
		// alert(endDate);
		this.dateChange();
	}

	dateChange() {
		if (this.fromDate != localStorage.getItem('fromDate') || this.toDate != localStorage.getItem('fromDate')) {
			localStorage.setItem('fromDate', this.fromDate);
			localStorage.setItem('toDate', this.toDate);
			this.getOverViewCards();
			this.getFacebookOverViewCards();
			this.overviewDataService.insideCardComponentData = [];
		}

		if (!this.overviewDataService.overviewGoogleDataList && !this.overviewDataService.overviewFacebookDataList) {
			if (this.fromDate && this.toDate) {
				this.getOverViewCards();
				this.getFacebookOverViewCards();
			}
		}
	}
}
