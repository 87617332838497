<ng-container *ngIf="!isLoading; else loading">
	<div class="custom-dropdown">
		<label for="">{{ config.label }}</label>
		<ng-container *ngIf="config.data.length; else noData">
			<mat-select
				name="countryString"
				[panelClass]="config.multi ? 'multi-dropdown' : ''"
				[id]="config.label"
				[formControl]="config.formController"
				placeholder="Select"
				disableOptionCentering
				[multiple]="config.multi"
				(selectionChange)="handleSelection($event)"
			>
				<div class="search-box" *ngIf="config.showSearch">
					<input class="search-box" placeholder="Search" (keyup)="search($event)" />
				</div>

				<mat-option selected hidden>None</mat-option>
				<div class="item" *ngFor="let c of config.data">
					<!-- <mat-checkbox></mat-checkbox> -->
					<mat-option [value]="c">{{ c.name }}</mat-option>
				</div>
			</mat-select>
		</ng-container>
		<ng-template #noData>
			<div class="no-data">
				{{ config.emptyMessage }}
			</div>
		</ng-template>
	</div>
</ng-container>

<ng-template #loading>
	<div class="loading">
		<ngx-skeleton-loader appearance="line" style="height: 48px; width: 200px;" [theme]="{ width: '200px', height: '48px' }"></ngx-skeleton-loader>
	</div>
</ng-template>
