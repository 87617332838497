import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { AuthenticationService } from './_services/authentication.service';
import { InterceptorsProvider } from './_services/interceptors/intercepors.provider';
import { ExcelService } from './_services/excel.service';

import { SidenavService } from './_services/side-nav.service';
import { TableService } from './_services/table.service';
import { UserServiceSubscription } from './_services/user/user-subscription.service';
import { UserServiceApi } from './_services/user/user.api.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { SidenavModule } from './sidenav/sidenav.module';
import { UserRoleAdminGuard } from './shared/guards/user-role-admin-guard.service';
import { from, Observable } from 'rxjs';
import { AuthenticationRedirectGuard } from './shared/guards/authentication-redirect.guard';
import { AccountTypeService } from './shared/account-type.service';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UrlSerializer } from '@angular/router';

import { CaseInsensitiveUrlSerializer } from './shared/case-insensitive-url-serializer.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { ComponentRegistryService } from './shared/component-registry.service';
import { clearStateMetaReducer, initialAppState, REDUCER_TOKEN, reducers } from './state/app.state';
import { MapperService } from './shared/services/mapper.service';
import { ErrorsLoggingService } from './_services/errors-logging/errors-logging.service';
import { ErrorsLoggingServiceApi } from './_services/errors-logging/errors-logging-api.service';
import { NgxEchartsModule } from 'ngx-echarts';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { FiledLibMapperService } from './shared/services/filed-lib-Mapper.service';
import { UserBusinessOwnerGuard } from './shared/guards/user-business-owner.guard';
import { AuthenticationRouteGuard } from './shared/guards/authentication-route.guard';
import { UserBlockGuard } from './shared/guards/user-block.guard';
import { EffectsModule } from '@ngrx/effects';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { QuillModule } from 'ngx-quill';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { EditorService } from './shakespeare/ads/facebook/editor/editor.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TestComponent } from './test/test.component';
import { SharedDropdownModule } from './shared-dropdown/shared-dropdown.module';
import { Test2Component } from './test/test2/test2.component';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		return from(import(`../assets/i18n/${lang}.json`));
	}
}

@NgModule({
	declarations: [AppComponent, TestComponent, Test2Component],
	exports: [],
	imports: [
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		}),
		StoreModule.forRoot(REDUCER_TOKEN, {
			metaReducers: [clearStateMetaReducer],
			initialState: initialAppState,
			runtimeChecks: {
				strictStateImmutability: false,
				strictActionImmutability: false
			}
		}),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			name: 'Filed',
			maxAge: 50
		}),
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		SharedModule,
		SidenavModule,
		QuillModule.forRoot(),
		NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'This item is actually loading...' }),
		AuthenticationModule,
		NgxEchartsModule.forRoot({
			echarts: () => import('echarts')
		}),
		NotificationModule,
		DragToSelectModule.forRoot(),
		ChartsModule,
		DateInputsModule,
		SharedDropdownModule
	],
	providers: [
		{
			provide: REDUCER_TOKEN,
			useValue: reducers
		},
		ErrorsLoggingService,
		EditorService,
		ErrorsLoggingServiceApi,
		MapperService,
		FiledLibMapperService,
		AuthenticationService,
		UserServiceApi,
		UserServiceSubscription,
		SidenavService,
		TableService,
		ExcelService,
		AuthenticationGuard,
		UserRoleAdminGuard,
		InterceptorsProvider,
		AuthenticationRedirectGuard,
		AccountTypeService,
		UserBusinessOwnerGuard,
		AuthenticationRouteGuard,
		UserBlockGuard,

		ComponentRegistryService,
		{
			provide: UrlSerializer,
			useClass: CaseInsensitiveUrlSerializer
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor() {}
}
