import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FacebookDataService } from 'src/app/shakespeare/ads/facebook/facebook-data.service';

@Injectable({
	providedIn: 'root'
})
export class SharedDropdownService {
	config = {
		type: '',
		label: '',
		multi: false
	};

	AdAccount = new BehaviorSubject(null);
	AdAccount$ = this.AdAccount.asObservable();
	aiCategory = new BehaviorSubject(null);
	aiCategory$ = this.aiCategory.asObservable();
	adSets = new BehaviorSubject([]);
	adSets$ = this.adSets.asObservable();
	platform = new BehaviorSubject(null);
	platform$ = this.platform.asObservable();
	campaign = new BehaviorSubject([]);
	campaign$ = this.campaign.asObservable();
	platforms = [
		{ id: 'google', name: 'Google' },
		{ id: 'facebook', name: 'Facebook' }
	];

	defaultPlatform = 'facebook';

	AiOptimizeOptions = ['platform', 'adAccount', 'adCampaign'];
	facebookAndGoogleOptions = ['adAccount', 'aiCategory', 'adCampaign', 'adSet'];
	facebookDropdownStructure = ['adAccount', 'aiCategory', 'adSet'];
	aiCalibrationOptions = ['platform', 'adAccount'];
	all = ['platform', 'adAccount', 'aiCategory', 'adCampaign', 'adSet'];

	constructor(private facebookService: FacebookDataService) {}

	generateConfig() {
		return;
	}

	getOutputkey(option) {
		return option[option.length - 1];
	}

	loadFbAdAccount() {
		return this.facebookService.fbAccounts;
	}

	loadAICategory() {
		return this.facebookService.fbAiCategory;
	}

	loadAdGroup() {}
	loadAdCampaign() {}

	getGooglePlatform() {
		return this.platforms.filter(v => v.id == 'google');
	}
	getFbPlatform() {
		return this.platforms.filter(v => v.id == 'facebook');
	}
	getAllPlatform() {
		return this.platforms;
	}
}
