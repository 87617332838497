import { Injectable } from '@angular/core';
import { ISidemenuOptionParent } from '../models/sidemenu';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SidemenuService {
	constructor() {}
	toggleSideMenu = new BehaviorSubject(true);
	toggleSideMenu$ = this.toggleSideMenu.asObservable();
	sideMenuOptions: Array<ISidemenuOptionParent> = [
		{
			name: 'Overview',
			route: '/layout/overview',
			expand: false,
			hasChild: false,
			icon: 'assets/icons/nav-menu/submenu-icons/home-icon.svg',
			selected: false,
			rightIcon: true,
			id: 1,
			tourStep: 'tour-overview'
		},
		{
			name: 'AI Creative',
			route: '',
			expand: false,
			hasChild: true,
			icon: 'assets/icons/nav-menu/submenu-icons/home-icon.svg',
			selected: false,
			rightIcon: false,
			id: 2,
			tourStep: 'tour-AI-Creative',

			child: [
				{
					name: 'Facebook',
					route: '/layout/ads/facebook/dashboard',
					icon: 'assets/icons/nav-menu/submenu-icons/facebook-nav.svg',
					rightIcon: true,
					selected: true,
					id: 2.1
				},
				{
					name: 'Google',
					route: '/layout/ads/google/dashboard',

					icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
					rightIcon: true,
					selected: false,
					id: 2.2
				}
			]
		},
		{
			name: ' AI Optimization',
			route: '',
			expand: false,
			hasChild: true,
			icon: 'assets/icons/nav-menu/submenu-icons/home-icon.svg',
			selected: false,
			rightIcon: false,
			id: 3,
			tourStep: 'tour-AI-Optimization',
			child: [
				{
					name: 'Recommendations',
					route: '/layout/optimization-ai/ai-recommendation',
					icon: 'assets/icons/nav-menu/submenu-icons/facebook-nav.svg',
					rightIcon: false,
					selected: false,
					id: 3.1
				},
				{
					name: 'Action Impact Analysis',
					route: '/layout/optimization-ai/action-impact-analysis',
					icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
					rightIcon: false,
					selected: false,
					id: 3.2
				},
				{
					name: 'Performance Insights',
					route: '/layout/optimization-ai/performance-impact',
					icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
					rightIcon: false,
					selected: false,
					id: 3.3
				},
				{
					name: 'AI Calibration',
					route: '/layout/optimization-ai/AiCalibration',
					icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
					rightIcon: false,
					selected: false,
					id: 3.4
				}
			]
		},
		{
			name: 'AI Targeting',
			route: '',
			expand: false,
			hasChild: true,
			icon: 'assets/icons/nav-menu/submenu-icons/home-icon.svg',
			selected: false,
			rightIcon: false,
			id: 4,
			tourStep: 'tour-AI-Targeting',
			child: [
				{
					name: 'Facebook',
					route: '/layout/ai-targeting/facebook',
					icon: 'assets/icons/nav-menu/submenu-icons/facebook-nav.svg',
					rightIcon: true,
					selected: false,
					id: 4.1
				},
				{
					name: 'Google',
					route: '/layout/ai-targeting/google',
					icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
					rightIcon: true,
					selected: false,
					id: 4.2
				}
			]
		}
	];
}
