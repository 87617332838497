import { environment } from 'src/environments/environment.dev3';

const googleUrl = environment.BaseUrl + 'google/';
export const googleAccessToken = environment.BaseUrl + 'google-access-token';
export const updateAccessToken = googleUrl + 'update-access-token';
export const validateAccessToken = googleUrl + 'validate-access-token';
export const getAccounts = googleUrl + 'ad-accounts';
export const getCampaign = googleUrl + 'customer/{accountId}/campaigns';
export const adSets = googleUrl + 'ad-groups/customer/{accountId}/campaigns/{campaignId}';
export const googleDisconnect = googleUrl + 'disconnect';
