import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.dev3';

@Injectable()
export class BaseApiUrl {
	public static Users = environment.SharedServices.Users;
	public static UserVerification = environment.UserVerification;
	public static Contact = environment.SharedServices.Contact;
	public static GeneralSettings = environment.SharedServices.GeneralSettings;
	public static Subscription = environment.SharedServices.Subscription;
	public static Optimization = environment.SharedServices.Optimization;
	public static SubscriptionSecond = environment.SharedServices.SubscriptionSecond;
	public static FiledSupport = environment.SharedServices.Support;
	public static Payment = environment.Payment;
	public static BlueSnap = environment.BlueSnap;

	public static FacebookDataPipeline = environment.Facebook.DataPipeline;
	public static Dexter = environment.Facebook.Dexter;
	public static DexterAnalytics = environment.Dexter.DexterAnalytics;
	public static DexterGenesis = environment.Dexter.Genesis;
	public static Creator = environment.Dexter.Creator;
	public static DexterDashboard = environment.Dexter.DexterDashboard;
	public static Reporting = environment.SharedMarketing.Reporting;
	public static Assets = environment.SharedMarketing.Assets;

	public static PythonGoogleAccounts = environment.Google.PythonAccounts;
	public static GoogleDataPipeline = environment.Google.DataPipeLine;

	public static HubSpot = environment.Misc.HubSpot;
	public static LogErrors = environment.Misc.LogErrors;

	public static CreativeBuilder = environment.CreativeBuilders.CreativeBuilder;

	public static ProductCatalog = environment.ProductCatalogs;
	public static PythonCatalogLambda = environment.PythonCatalogLambdas;
	public static SocialInfluencerPython = environment.SocialInfluencerApi.SocialInfluencerPython;
	public static SocialMessengerPython = environment.SocialInfluencerApi.SocialMessengerPython;
	public static SocialInfluencerCS = environment.SocialInfluencerApi.SocialInfluencerListAPI;
	public static SocialMessengerSocket = environment.SocialInfluencerApi.SocialMessengerSocket;
	public static AdIntelligence = environment.AdIntelligence;
	// public static Auth = environment.Auth;

	public static Accounts = environment.ACCOUNTS;
}
