import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SharedDropdownService } from 'src/app/shared-dropdown/service/shared-dropdown.service';

@Component({
	selector: 'app-test2',
	templateUrl: './test2.component.html',
	styleUrls: ['./test2.component.scss']
})
export class Test2Component implements OnInit {
	@Input() type: string;
	filterCountries;

	constructor(public sharedDropdown: SharedDropdownService) {
		this.filterCountries = [...this.countries];
	}
	ngOnInit(): void {}

	countries: any = [
		{
			full: 'Great Britain',
			short: 'GB'
		},
		{
			full: 'United States',
			short: 'US'
		},
		{
			full: 'Canada',
			short: 'CA'
		},
		{
			full: 'Great Britain',
			short: 'GB'
		},
		{
			full: 'United States',
			short: 'US'
		},
		{
			full: 'Canada',
			short: 'CA'
		},
		{
			full: 'Great Britain',
			short: 'GB'
		},
		{
			full: 'United States',
			short: 'US'
		},
		{
			full: 'Canada',
			short: 'CA'
		},
		{
			full: 'Great Britain',
			short: 'GB'
		},
		{
			full: 'United States',
			short: 'US'
		},
		{
			full: 'Canada',
			short: 'CA'
		},
		{
			full: 'Great Britain',
			short: 'GB'
		},
		{
			full: 'United States',
			short: 'US'
		},
		{
			full: 'Canada',
			short: 'CA'
		},
		{
			full: 'Great Britain',
			short: 'GB'
		},
		{
			full: 'United States',
			short: 'US'
		},
		{
			full: 'Canada',
			short: 'CA'
		},
		{
			full: 'Great Britain',
			short: 'GB'
		},
		{
			full: 'United States',
			short: 'US'
		},
		{
			full: 'Canada',
			short: 'CA'
		},
		{
			full: 'Great Britain',
			short: 'GB'
		},
		{
			full: 'United States',
			short: 'US'
		},
		{
			full: 'Canada',
			short: 'CA'
		},
		{
			full: 'Great Britain',
			short: 'GB'
		},
		{
			full: 'United States',
			short: 'US'
		},
		{
			full: 'Canada',
			short: 'CA'
		},
		{
			full: 'Great Britain',
			short: 'GB'
		},
		{
			full: 'United States',
			short: 'US'
		},
		{
			full: 'Canada',
			short: 'CA'
		},
		{
			full: 'Great Britain',
			short: 'GB'
		},
		{
			full: 'United States',
			short: 'US'
		},
		{
			full: 'Canada',
			short: 'CA'
		}
	];
	selectedCountry: string = '';

	selectedCountryControl = new FormControl(this.selectedCountry);

	search(e) {
		let val = e.target.value;
		console.log(val);
		this.filterCountries = this.countries.filter(v => {
			return v?.full.toLowerCase().includes(val.toLowerCase());
		});
	}

	handleDropDown(e) {
		console.log(e, 'from test component all dropdowns loaded');
	}
}
