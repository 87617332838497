export interface ISignIn {
	Email: string;
	Password: string;
}
export interface ISignUp {
	Email: string;
	Password: string;
	FirstName: string;
	LastName: string;
}

export interface ISignInResponse {
	access_token: string;
	message: string;
}
export interface ISignUpResponse extends ISignInResponse {
	user_id: number;
}
export interface IResetPassword {
	Email: string;
}
export interface IUpdatePassword extends ISignIn {}
