import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoogleDataService } from 'src/app/shakespeare/ads/google/services/google-data.service';
import { environment } from 'src/environments/environment.dev3';
@Component({
	selector: 'app-google-connection',
	templateUrl: './google-connection.component.html',
	styleUrls: ['./google-connection.component.scss']
})
export class GoogleConnectionComponent implements OnInit {
	loading = false;
	constructor(private activatedRoute: ActivatedRoute, private googleService: GoogleDataService) {}
	ngOnInit(): void {
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get('code');
		console.log(code, 'code');
		this.getGoogleToken(code);
	}

	getGoogleToken(code) {
		this.googleService.sendCodeToGoogle(code).subscribe(res => {
			res = { ...res, code: code };
			this.sendGoogleObject(res);
		});
	}

	sendGoogleObject(obj): void {
		console.log(obj);
		this.googleService.getGoogleAccessToken(obj).subscribe({
			next: res => {
				console.log(res);

				if (!res) {
					localStorage.setItem('googleConnected', 'false');
				}
				if (res) {
					localStorage.setItem('googleConnected', 'true');
				}
				this.loading = false;
				this.googleService.googleConnection.next(true);
			},
			error: () => {
				console.log('error ');
				// localStorage.setItem('googleConnected', 'false');
				// this.toastNotificationService.sendErrorToast('Gmail account is already used in Shakespeare');
				this.loading = false;

				// this.router.navigate(['ads/google/auth']);
			}
		});
	}

	public getCookie(name: string) {
		let ca: Array<string> = document.cookie.split(';');
		let caLen: number = ca.length;
		let cookieName = `${name}=`;
		let c: string;

		for (let i: number = 0; i < caLen; i += 1) {
			c = ca[i].replace(/^\s+/g, '');
			if (c.indexOf(cookieName) == 0) {
				return c.substring(cookieName.length, c.length);
			}
		}
		return '';
	}

	saveCookie(name: string, value: string): void {
		const date = new Date();
		// Set it expire in 1 year
		date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
		const expires = '; expires=' + date.toUTCString();
		// with domain and path
		document.cookie = name + '=' + (value || '') + expires + '; domain=.shakespeare.ai; path=/';
	}
}
