import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SkeletonLoaderService {
	loader = new BehaviorSubject(false);
	loader$ = this.loader.asObservable();

	constructor() {}

	showLoader() {
		this.loader.next(true);
	}
	hideLoader() {
		this.loader.next(false);
	}
}
