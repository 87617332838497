import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, map } from 'rxjs';
import * as googleApi from './model/google.api';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev3';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
@Injectable({
	providedIn: 'root'
})
export class GoogleDataService {
	private dataSubject = new BehaviorSubject<any>(null);
	private manageAiSub = new BehaviorSubject<any>(null);

	// Observable to be subscribed to by the receiver component
	data$ = this.dataSubject.asObservable();
	manageAi$ = this.manageAiSub.asObservable();

	googleAdAccount = [];

	googleConnection = new BehaviorSubject(null);
	googleConnection$ = this.googleConnection.asObservable();
	constructor(private http: HttpClient, private toastNotificationService: ToastNotificationService) {}

	// Method to send data to the receiver component
	sendData(data: any) {
		this.dataSubject.next(data);
	}
	sendManageAiSub(data: any) {
		this.manageAiSub.next(data);
	}

	sendCodeToGoogle(code) {
		// redirectUri =
		let json = {
			code: code,
			client_id: environment.GOOGLE_CLIENT_ID,
			client_secret: environment.google_secret_key,
			redirect_uri: 'https://localhost:4200/googleAuth',
			grant_type: 'authorization_code'
		};
		return this.http.post('https://oauth2.googleapis.com/token', json);
	}

	getGoogleAccessToken(code) {
		return this.http.put(googleApi.googleAccessToken, code);
	}
	updateGoogleAccessToken(payload) {
		return this.http.put(googleApi.updateAccessToken, payload);
	}
	validateGoogleAccessToken() {
		return this.http.get(googleApi.validateAccessToken);
	}
	getGoogleAdAccount() {
		return this.http
			.get(googleApi.getAccounts)
			.pipe
			// map(res => {
			// 	res['ad_accounts'] = res['ad_accounts'].map(v => {
			// 		v.id = v.id.slice(4);
			// 		return v;
			// 	});
			// 	return res;
			// })
			();
	}

	getGoogleCampaign(fbAccountId: string) {
		let url = googleApi.getCampaign.replace('{accountId}', fbAccountId);
		return this.http.get(url).pipe(
			map(g => {
				let data = g['campaigns'];
				g['campaigns'] = data.map(c => {
					c.biddingStrategyType = c.bidding_strategy_type;
					return c;
				});
				return g;
			})
		);
	}
	getGoogleAdSet(accountId, campaignId) {
		let url = googleApi.adSets.replace('{campaignId}', campaignId).replace('{accountId}', accountId);

		return this.http.get(url);
	}

	googleDisconnect() {
		return this.http.put(googleApi.googleDisconnect, {});
	}

	// googleRedirectUrl = environment.Flags.production ? 'app.shakespeare.ai' : 'dev.shakespeare.ai';
	googleRedirectUrl = location.origin + '/googleAuth';
	// googleRedirectUrl = 'http://localhost:5000/google-access-token';
	connectToGoogle(): string {
		// this.saveCookie('googleRedirectType', 'AiIntelligence');
		const url = this.googleRedirectUrl;
		const id = environment.GOOGLE_ADS_ACCOUNT_ID;
		if (url && id) {
			let googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${id}&redirect_uri=${url}&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/content&access_type=offline&include_granted_scopes=true`;
			`https://accounts.google.com/o/oauth2/v2/auth?client_id=${id}&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/content&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=https://${url}/googleAuth`;
			return googleUrl;
		}
	}

	createOauthWindow(url: string, name = 'Authorization', width = 500, height = 600, left = 0, top = 0) {
		if (url == null) {
			return null;
		}
		const CLIENT_URL = this.googleRedirectUrl;
		left = screen.width / 2 - width / 2;
		top = screen.height / 2 - height / 2;
		const options = `width=${width},height=${height},left=${left},top=${top}`;
		const popup = window.open(url, name, options);
		const checkPopup = setInterval(() => {
			let connected = localStorage.getItem('googleConnected');
			if (connected) {
				console.log(connected);
				clearInterval(checkPopup);
				popup.close();
				localStorage.removeItem('googleConnected');

				if (connected == 'true') {
					this.googleConnection.next(true);
					// this.router.navigate(['ads/google/auth'], { queryParams: { connected: true } });
				} else this.toastNotificationService.sendErrorToast('Gmail account connection failed!');
			}
			if (!popup || !popup.closed) return;
			clearInterval(checkPopup);
		}, 1000);
	}

	saveCookie(name: string, value: string): void {
		const date = new Date();
		// set expire in 1 day
		date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
		const expires = '; expires=' + date.toUTCString();
		// with domain and path
		document.cookie = name + '=' + (value || '') + expires + '; domain=.shakespeare.ai; path=/';
	}
}
