import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthV2Service } from '../services/auth-v2.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private authService: AuthV2Service) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const user = this.authService.isUserLogsIn();
		console.log(user, 'user auth guard');
		if (user) {
			// authorised so return true
			return true;
		}

		// not logged in so redirect to login page with the return url
		this.router.navigateByUrl('/login');
		return false;
	}
}
