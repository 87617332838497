import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-app-loader',
	templateUrl: './app-loader.component.html',
	styleUrls: ['./app-loader.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AppLoaderComponent implements OnInit {
	@Input() public rotating: boolean;
	@Input() public background: string;

	constructor() {}

	ngOnInit(): void {
		this.rotating = true;
	}
}
