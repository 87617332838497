<mat-sidenav-container class="sidenav-container">
	<mat-sidenav
		#drawer
		class="sidenav"
		fixedInViewport
		[attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
		[mode]="(isHandset$ | async) ? 'over' : 'side'"
		[opened]="isSidemenuOpened"
	>
		<mat-toolbar>
			<img src="assets/shakespeare/shakespeare-logo.svg" />
		</mat-toolbar>
		<ng-container *ngFor="let option of sideMenuOptions; let parentIndex = index">
			<div
				class="non-expand-menu {{ option.tourStep }}"
				[class.selected]="option.id == selectedElement"
				*ngIf="!option.hasChild"
				(click)="handleSideMenuOption(option, parentIndex, null)"
			>
				{{ option.name }}
				<img src="assets/icons/nav-menu/submenu-icons/home-icon.svg" alt="" />
			</div>
			<mat-accordion *ngIf="option.hasChild" multi>
				<mat-expansion-panel hideToggle (opened)="option.expand = true" (closed)="option.expand = false" [expanded]="option.expand">
					<mat-expansion-panel-header class="{{ option.tourStep }}">
						<div class="expand-title">
							<mat-icon *ngIf="option.expand">arrow_drop_down</mat-icon>
							<mat-icon *ngIf="!option.expand">arrow_right</mat-icon>

							<div class="option-title">
								{{ option.name }}
							</div>
						</div>
					</mat-expansion-panel-header>
					<div
						class="expand-child"
						(click)="handleSideMenuOption(childOption, parentIndex, childIndex)"
						[class.selected]="childOption.id == selectedElement"
						*ngFor="let childOption of option.child; let childIndex = index"
					>
						{{ childOption.name }}
						<img *ngIf="childOption.rightIcon" [src]="childOption.icon" alt="" />
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</ng-container>
	</mat-sidenav>
	<mat-sidenav-content>
		<mat-toolbar color="primary">
			<!-- <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button> -->
			<!-- <span class="example-spacer"></span> -->
			<div class="header-item-logo">
				<!-- <mat-icon (click)="sideMenuToggle(isSidemenuOpened )" class="hamburger">menu</mat-icon> -->
				<div class="hamburger" (click)="goHome()">
					<mat-icon *ngIf="!isSidemenuOpened">arrow_back_ios</mat-icon>
					<!-- <mat-icon>
						arrow_back</mat-icon> -->
				</div>
				<!-- <img src="assets/shakespeare/shakespeare-logo.svg" /> -->
			</div>
			<div class="header-items">
				<div class="notification-icon">
					<app-notifications-dialog
						*ngIf="!isShowAllNotification"
						[notifications]="notifications"
						(updateUnreadCount)="updateUnreadCountEvent($event)"
					></app-notifications-dialog>
				</div>
				<div (click)="openKnowledgeBase()" class="help-icon tour-knowledge">
					<img src="assets/shakespeare/help_icon.svg" alt="" />
				</div>
				<div
					mat-button
					[matMenuTriggerFor]="menu"
					class="user-block"
					(menuClosed)="handleProfileToggle = false"
					(menuOpened)="handleProfileToggle = true"
				>
					<img src="assets/templates/logged_user.svg" alt="" />
					<div class="username">
						{{ userDetail.first_name | titlecase }}
					</div>
					<mat-icon *ngIf="!handleProfileToggle">keyboard_arrow_down</mat-icon>
					<mat-icon *ngIf="handleProfileToggle">keyboard_arrow_up</mat-icon>
				</div>
				<mat-menu class="profile-menu" #menu="matMenu">
					<div class="profile-menu__user-info">
						<div class="profile-menu__user-info__name">
							{{ userDetail.first_name + ' ' + userDetail.last_name | titlecase }}
						</div>
						<div class="profile-menu__user-info__email">
							{{ userDetail.email }}
						</div>
					</div>
					<button mat-menu-item class="menu-item" (click)="goToSetting()">
						<!-- <img src="assets/setting.svg" /> -->
						<mat-icon>settings</mat-icon>
						Setting
					</button>
					<button mat-menu-item class="menu-item" (click)="signOut()">
						<!-- <img src="assets/signout.svg" /> -->
						<mat-icon>exit_to_app</mat-icon>
						Sign out
					</button>
				</mat-menu>
				<!-- <div #settingsPopup class="profile-card" (click)="$event.stopPropagation()" *ngIf="profileOpen">
          <div class="avatar-personal">
            <div class="avatar">
              <img *ngIf="companyDetails && companyDetails.logo"
                [src]="companyDetails.logo ? companyDetails.logo : 'assets/templates/logged_user.svg'" alt="" />
            </div>
            <div class="personal">
              <div class="top">{{ userFName + ' ' + userLName }}</div>
              <div class="bottom" title="{{ userEmail }}">{{ userEmail }}</div>
            </div>
          </div>
          <div (click)="goToBilling()" *ngIf="!isMobile" class="action">
            <img src="assets/icons/nav-menu/settings-dark-icon.svg" />
            <div class="text">{{ 'Settings' }}</div>
          </div>
          <div (click)="logout()" class="action sign">
            <img src="assets/icons/nav-menu/signout-icon.svg" />
            <div class="text">{{ 'Sign out' }}</div>
          </div>
        </div> -->
			</div>
		</mat-toolbar>

		<ng-content></ng-content>

		<!-- Add Content Here -->
	</mat-sidenav-content>
</mat-sidenav-container>
